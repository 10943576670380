import '../dashboard/DashBoard.scss'
import './AdvancedDashboard.scss'
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    FormControl,
    Grid, IconButton,
    InputLabel, Switch, Tooltip,
    Typography
} from "@mui/material";
import {routes} from "../../routing/routes";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {generatePath, useLocation, useNavigate, useParams} from "react-router-dom";
import {DashboardRow} from "../../components/dashboard/DashboardRow";
import {DASHBOARD_CONSTANTS} from "../../components/dashboard/Dashboard.utils";
import {TreeMap} from "../../components/visualization/tree-map/TreeMap";
import {BubbleMap} from "../../components/visualization/bubble-map/BubbleMap";
import {LineChart} from "../../components/visualization/line-chart/LineChart";
import {AdvancedTable, HeaderSpecification} from '../../components/data-table/AdvancedTable';
import {environment} from '../../env';
import {AdvancedDashboardHeaderFilters} from "./AdvancedDashboardHeaderFilters";
import {DonutChart} from "../../components/visualization/donut-chart/DonutChart";
import {AdvancedBarChart} from "../../components/visualization/bar-chart/AdvancedBarChart";
import {generateHomePath} from "../../routing/routing.utils";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {runInAction} from "mobx";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {Refresh} from "@mui/icons-material";

/**
 * These two graphs need to be styled better
 */
const DISABLE_TWO_GRAPHS: boolean = true;

export const AdvancedDashboard: React.FC = observer(() => {
    const {rootAppStore, p, bagStore, loadingBarStore, businessCaseGeneratorStore, synergyStore} = useStores();
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const isMergeXMergePage = location.pathname === routes.merge_x_merge_advanced_dashboard;
    const mergeXmergeDashboardId = p.p.mergeXmergeOpportunityDashboardBagId;
    const databagId = isMergeXMergePage ? (mergeXmergeDashboardId || bagStore.bagId) : bagStore.bagId;
    const showL4 = Boolean(p.p.showL4InOpportunityDashboard) && !isMergeXMergePage;
    const isMergeXCSingleDashboard = environment.package === 'merge_x_cleanup' && location.pathname === routes.merge_xc_single_advanced_dashboard;
    const hideParents = p.p.hideParentSupplierOpportunityDashboard || isMergeXCSingleDashboard;
    // TODO CAT-2195: This would be better configured by a field in databag or synergy model,
    //  that would specify if user is allowed to split opportunity dashboard

    const [isLoading, setIsLoading] = useState(false);

    // Check for a requested databag id change
    useEffect(() => {
        bagStore.advanced.setDatabagId(databagId);
    }, [databagId, bagStore.advanced])

    const isReady = Boolean(bagStore.advanced.kpi) && !bagStore.advanced.isLoading;
    useEffect(() => {
        if (!isReady) {
            loadingBarStore.start(true);
        } else {
            loadingBarStore.setComplete()
        }
    }, [isReady, loadingBarStore])

    useEffect(() => {
        runInAction(() => {
            businessCaseGeneratorStore.hasLoaded = false;
            businessCaseGeneratorStore.risksHaveLoaded = false;
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // TODO QA: Class re-definition inside the render loop?
    class HeaderSpecificationWithOrdering {
        onOrder?: () => void;

        constructor(
            public label: string, public key: string,
            public type: 'string' | 'number' | 'currency' | '2_decimal' | 'category' | 'selection' | 'string[]',
            ordering_prefix: string,
            noOrdering?: boolean,
        ) {
            if (!noOrdering) {
                this.onOrder = () => {
                    if (bagStore.advanced.getOrderingWithPrefix(ordering_prefix) === key)
                        bagStore.advanced.setOrderingWithPrefix('-' + key, ordering_prefix);
                    else
                        bagStore.advanced.setOrderingWithPrefix(key, ordering_prefix);
                    return;
                }
            }
        }
    }

    const debug = (val) => JSON.stringify(val, null, 2);

    const OPPORTUNITY_HEADERS: HeaderSpecificationWithOrdering[] = ([
        new HeaderSpecificationWithOrdering('L1', 'active_l1', 'category', 'opportunity_'),
        new HeaderSpecificationWithOrdering('L2', 'active_l2', 'category', 'opportunity_'),
        new HeaderSpecificationWithOrdering('L3', 'active_l3', 'category', 'opportunity_'),
        showL4 ? new HeaderSpecificationWithOrdering('L4', 'active_l4', 'category', 'opportunity_') : undefined,
        new HeaderSpecificationWithOrdering('Opportunity rank', 'opportunity_rank', '2_decimal', 'opportunity_'),
        new HeaderSpecificationWithOrdering('Spend', 'spend', 'currency', 'opportunity_'),
    ] as (undefined | HeaderSpecification)[]).filter(v => v !== undefined) as HeaderSpecification[];

    const SUP_BREAKDOWN_HEADERS = ([
        hideParents ? undefined : new HeaderSpecificationWithOrdering(
            p.p.parentSupplierSynergyAltName ?? 'Parent Supplier', 'sp_name', 'string', 'sup_breakdown_'),
        new HeaderSpecificationWithOrdering('Supplier name', 's_name', 'string', 'sup_breakdown_'),
        p.p.showSupplierIdInAdvDashboard ? new HeaderSpecificationWithOrdering('Supplier id', 's_id', 'string', 'sup_breakdown_') : undefined,
        new HeaderSpecificationWithOrdering('Supplier country', 's_country_code', 'string', 'sup_breakdown_'),
        p.p.synergyDashboardShowBusinessUnits ? new HeaderSpecificationWithOrdering('Business Unit', 'business_units', 'string[]', 'sup_breakdown_', true) : undefined,
        p.p.synergyDashboardShowCDescriptionFilter ? new HeaderSpecificationWithOrdering('Company', 'c_description', 'string', 'sup_breakdown_') : undefined,
        new HeaderSpecificationWithOrdering('L1', 'active_l1', 'category', 'sup_breakdown_'),
        new HeaderSpecificationWithOrdering('L2', 'active_l2', 'category', 'sup_breakdown_'),
        new HeaderSpecificationWithOrdering('L3', 'active_l3', 'category', 'sup_breakdown_'),
        showL4 ? new HeaderSpecificationWithOrdering('L4', 'active_l4', 'category', 'sup_breakdown_') : undefined,
        (synergyStore.isSynergyDatabag && p.opportunitySplitAllowed) ? new HeaderSpecificationWithOrdering('Source', 'p_src_databag_id', 'string', 'opportunity_') : undefined,
        p.p.synergyDashboardShowDatabagName ? new HeaderSpecificationWithOrdering('Dataset name', 'databag_name', 'string', 'sup_breakdown_') : undefined,
        new HeaderSpecificationWithOrdering(synergyStore.isSynergyDatabag ? 'Supplier category spend' : 'Total spend', 'total_spend', 'currency', 'sup_breakdown_'),
        synergyStore.isSynergyDatabag ? new HeaderSpecificationWithOrdering('Total spend', 'parent_spend', 'currency', 'sup_breakdown_', undefined) : undefined,
    ] as (undefined | HeaderSpecification)[]).filter(v => v !== undefined) as HeaderSpecification[];

    let pageTitle: string;
    if (isMergeXMergePage) {
        pageTitle = 'Synergy Dashboard';
    } else if (isMergeXCSingleDashboard) {
        pageTitle = 'Dataset Dashboard';
    } else {
        pageTitle = 'Opportunity Dashboard';
    }

    return <KoiFacade
        title={pageTitle}
        simpleAction={<>
            <Tooltip title="Refresh data">
                <span>
                <IconButton
                    size="small"
                    aria-label="refresh"
                    disabled={bagStore.advanced_job.already_running ?? true}
                    onClick={() => bagStore.advanced_job.createRecalculation()}
                    className="mx-2">
                    <Refresh/>
                </IconButton>
                </span>
            </Tooltip>
            {p.opportunitySplitAllowed && synergyStore.isSynergyDatabag &&
                <Tooltip title={bagStore.advanced.synergySplited ?
                    'These are comparison between sources of synergy file, you can combine them for overview.' :
                    'This is overview of synergy file, you can split it to compare sources.'}>
                    <span>
                    <Button variant="outlined" className="mx-4" onClick={() => {
                        bagStore.advanced.setSynergySplited(!bagStore.advanced.synergySplited);
                    }}>
                        {bagStore.advanced.synergySplited ? 'Combine' : 'Split'}
                    </Button>
                    </span>
                </Tooltip>}
        </>}
        back={generateHomePath(location, params, rootAppStore.app)}
        className="advanced-dashboard-page">

        <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
                {bagStore.advanced_job.already_running && <Grid container className="mt-4">
                    <Grid item xs={12}>
                        <Alert severity="warning" className="advanced-dashboard-alert mx-auto">
                            <AlertTitle>
                                A data refresh is still being processed
                            </AlertTitle>
                            This page might not be up to date.
                            {/*Estimated completion time: */}
                        </Alert>
                    </Grid>
                </Grid>}
                <Grid container
                      className="dashboard-v2-wrapper"
                      justifyContent="space-evenly"
                      spacing={0}>
                    <DashboardRow
                        title="Categories"
                        className="categories-row"
                        subtitle={bagStore.advanced.kpi ? String(bagStore.advanced.kpi.n_categories) : DASHBOARD_CONSTANTS.VALUE_LOADING}>
                    </DashboardRow>
                    <DashboardRow
                        title="Spend"
                        className="spend-row"
                        subtitle={bagStore.advanced.kpi ?
                            <CurrencyComponent v={bagStore.advanced.kpi.total_spend}
                                               nDigits={3}/> : DASHBOARD_CONSTANTS.VALUE_LOADING}>
                    </DashboardRow>
                    <DashboardRow
                        title="Suppliers"
                        className="suppliers-row"
                        subtitle={bagStore.advanced.kpi ? String(bagStore.advanced.kpi.n_suppliers) : DASHBOARD_CONSTANTS.VALUE_LOADING}>
                    </DashboardRow>
                    <DashboardRow
                        title="Transactions"
                        className="transactions-row"
                        subtitle={bagStore.advanced.kpi ?
                            (bagStore.advanced.kpi.total_transactions ? String(bagStore.advanced.kpi.total_transactions) : '0') : DASHBOARD_CONSTANTS.VALUE_LOADING}
                    >
                    </DashboardRow>
                </Grid>

                {environment.isTest && <>
                    {/* Debug info */}
                    <pre
                        style={{
                            maxWidth: '500px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            height: '10em',
                            maxHeight: '10em'
                        }}>
                    {debug(bagStore.advanced.filters)}
                </pre>
                </>}

                <AdvancedDashboardHeaderFilters showL4={showL4}/>

                {p.p.demoFakeBusinessCaseGenerator && bagStore.advanced.filters.category && (
                    <Alert
                        severity="info"
                        action={
                            <>
                                <div style={{display: 'flex', alignItems: 'baseline'}}>
                                    <FormControl variant='standard' style={{width: '20em', marginRight: '1em'}}>
                                        <InputLabel id="business-case-dropdown-label">Select Strategy</InputLabel>
                                        <Select
                                            labelId="business-case-dropdown-label"
                                            id="business-case-dropdown"
                                            label="Select Strategy"
                                            onChange={(event) => {
                                                runInAction(() => {
                                                    businessCaseGeneratorStore.selectedStrategy = event.target.value;
                                                });
                                            }}
                                        >
                                            <MenuItem value={1}>Demand / supplier consolidation</MenuItem>
                                            <MenuItem value={2}>Time to market ( TTM ) optimization</MenuItem>
                                            <MenuItem value={3}>Tail spend reduction</MenuItem>
                                            <MenuItem value={4}>Optimize cash flow</MenuItem>
                                            <MenuItem value={5}>Price variation optimization</MenuItem>
                                            <MenuItem value={6}>Multi - source strategy</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Button
                                        style={{width: '15em'}}
                                        disabled={!businessCaseGeneratorStore.selectedStrategy || isLoading}
                                        onClick={() => {
                                            setIsLoading(true);
                                            // Simulate a delay for 1.8 seconds and perform your actions
                                            setTimeout(() => {
                                                runInAction(() => {
                                                    businessCaseGeneratorStore.hasLoaded = false;
                                                    businessCaseGeneratorStore.pageController.navigateToPage('review-opportunities');
                                                });
                                                navigate(generatePath(routes.job_business_case_report, {id: bagStore.bagId}));
                                                setIsLoading(false);
                                            }, 1800);
                                        }}
                                    >
                                        {isLoading ? 'Generating...' : 'Generate Business Case'}
                                    </Button>
                                </div>
                            </>
                        }
                    >
                        {/*<AlertTitle>You can use Gen AI to build your Business case</AlertTitle>*/}
                        {/*Based on the data in this dashboard, you can generate a business case.*/}
                        <AlertTitle>Select scope and defined category strategy to build your business case</AlertTitle>
                        Based on this selection, you can use Mithra Ai assistant to kick start a business case
                    </Alert>
                )}

                <Grid container>
                    {!bagStore.advanced.synergySplited && <Grid container item>
                        <Grid item xs={6} className="advanced-dashboard-chart">
                            <BubbleMap
                                data={bagStore.advanced.geoData}
                                countryFilterChanged={bagStore.advanced.countryFilterChanged}
                                selectedCountries={bagStore.advanced.filters.country}
                            />
                        </Grid>
                        <Grid item xs={6} className="advanced-dashboard-chart">
                            {/*treeData: {'' + bagStore.advanced.treeData.children.length}*/}
                            <TreeMap
                                data={bagStore.advanced.treeData}
                                selectedCategory={bagStore.advanced.filters.category ?? null}
                                categoryFilterChanged={bagStore.advanced.onTreeMapCategoryFilterChanged}
                            />
                        </Grid>
                        <Grid item xs={6} className="advanced-dashboard-chart">
                            <Card className="advanced-dashboard-chart-card">
                                <CardContent>
                                    <Typography variant={'h6'} className={'advanced-dashboard-title'}>
                                        Spend breakdown
                                        {p.p.enableParentSupplierAdvDashboard && synergyStore.isSynergyDatabag &&
                                            <Typography variant="caption" className="advanced-dashboard-title mx-4">
                                                <Switch
                                                    checked={bagStore.advanced.groupParentSuppliersFilter}
                                                    onChange={(_e, checked: boolean) =>
                                                        bagStore.advanced.groupParentSuppliersFilter = checked
                                                    }/>
                                                {p.p.parentSupplierSynergyAltName ?? 'Parent supplier'}
                                            </Typography>}
                                    </Typography>
                                    <Typography sx={{fontSize: 14}} color="text secondary" gutterBottom
                                                className={'advanced-dashboard-subtitle'}>
                                        Spend distribution by top suppliers
                                    </Typography>
                                    <Divider className="mb-5"/>
                                    <DonutChart
                                        data={bagStore.advanced.spendDonutData}
                                        loading={bagStore.advanced.advancedSpendDonutDataRM.busy}
                                        onClickedSupplier={bagStore.advanced.groupParentSuppliersFilter ? bagStore.advanced.parentSupplierFilterChanged : bagStore.advanced.supplierFilterChanged}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={6} className="advanced-dashboard-chart">
                            <Card className="advanced-dashboard-chart-card">
                                <CardContent>
                                    <Typography variant={'h6'} className={'advanced-dashboard-title'}>
                                        Supplier spend
                                        {p.p.enableParentSupplierAdvDashboard && synergyStore.isSynergyDatabag &&
                                            <Typography variant="caption" className="advanced-dashboard-title mx-4">
                                                <Switch
                                                    checked={bagStore.advanced.groupParentSuppliersFilter}
                                                    onChange={(_e, checked: boolean) =>
                                                        bagStore.advanced.groupParentSuppliersFilter = checked
                                                    }/>
                                                {p.p.parentSupplierSynergyAltName ?? 'Parent supplier'}
                                            </Typography>}
                                    </Typography>
                                    <Typography sx={{fontSize: 14}} color="text secondary" gutterBottom
                                                className={'advanced-dashboard-subtitle'}>
                                        Total spend distribution per supplier
                                    </Typography>
                                    <Divider/>
                                    <AdvancedBarChart
                                        dataWrapper={bagStore.advanced.spendConcentrationData}
                                        loading={bagStore.advanced.advancedSpendConcentrationDataRM.busy}
                                        selectedSuppliers={bagStore.advanced.filters.s_ids}
                                        supplierFilterChanged={bagStore.advanced.groupParentSuppliersFilter ? bagStore.advanced.parentSupplierFilterChanged : bagStore.advanced.supplierFilterChanged}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>}

                    {bagStore.advanced.synergySplited && <Grid container item>
                        {bagStore.advanced.srcDatabagIds.map((databagId, index) =>
                            <Grid item key={index} xs={12 / bagStore.advanced.srcDatabagIds.length}>
                                <Typography variant="h5" component="p" style={{textAlign: 'center'}}>
                                    {bagStore.getBagName(databagId)}
                                </Typography>
                            </Grid>
                        )}
                        {/*TODO CAT-2195: The limitation here is that number of sources must be less than or equal to 4*/}
                        {bagStore.advanced.treeDataSplited.map((atd, index) =>
                            <Grid item key={index} xs={12 / bagStore.advanced.treeDataSplited.length}
                                  className="advanced-dashboard-chart">
                                <TreeMap
                                    data={atd}
                                    selectedCategory={bagStore.advanced.filters.category ?? null}
                                    categoryFilterChanged={bagStore.advanced.onTreeMapCategoryFilterChanged}
                                />
                            </Grid>)}
                        {bagStore.advanced.spendDonutDataSplited.map((dcssd, index) =>
                            <Grid item key={index} xs={12 / bagStore.advanced.spendDonutDataSplited.length}
                                  className="advanced-dashboard-chart">
                                <Card className="advanced-dashboard-chart-card">
                                    <CardContent>
                                        <Typography variant={'h6'} className={'advanced-dashboard-title'}>
                                            Spend breakdown
                                            {p.p.enableParentSupplierAdvDashboard &&
                                                <Typography variant="caption" className="advanced-dashboard-title mx-4">
                                                    <Switch
                                                        checked={bagStore.advanced.groupParentSuppliersFilter}
                                                        onChange={(_e, checked: boolean) =>
                                                            bagStore.advanced.groupParentSuppliersFilter = checked
                                                        }/>
                                                    {p.p.parentSupplierSynergyAltName ?? 'Parent supplier'}
                                                </Typography>}
                                        </Typography>
                                        <Typography sx={{fontSize: 14}} color="text secondary" gutterBottom
                                                    className={'advanced-dashboard-subtitle'}>
                                            Spend distribution by top suppliers
                                        </Typography>
                                        <Divider/>
                                        <DonutChart
                                            data={dcssd}
                                            loading={bagStore.advanced.advancedSpendDonutDataSplitedRMs[index].busy}
                                            onClickedSupplier={bagStore.advanced.groupParentSuppliersFilter ? bagStore.advanced.parentSupplierFilterChanged : bagStore.advanced.supplierFilterChanged}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>)}
                        {bagStore.advanced.spendConcentrationDataSplited.map((asd, index) =>
                            <Grid item key={index} xs={12 / bagStore.advanced.spendDonutDataSplited.length}
                                  className="advanced-dashboard-chart">
                                <Card className="advanced-dashboard-chart-card">
                                    <CardContent>
                                        <Typography variant={'h6'} className={'advanced-dashboard-title'}>
                                            Supplier spend
                                            {p.p.enableParentSupplierAdvDashboard &&
                                                <Typography variant="caption" className="advanced-dashboard-title mx-4">
                                                    <Switch
                                                        checked={bagStore.advanced.groupParentSuppliersFilter}
                                                        onChange={(_e, checked: boolean) =>
                                                            bagStore.advanced.groupParentSuppliersFilter = checked
                                                        }/>
                                                    {p.p.parentSupplierSynergyAltName ?? 'Parent supplier'}
                                                </Typography>}
                                        </Typography>
                                        <Typography sx={{fontSize: 14}} color="text secondary" gutterBottom
                                                    className={'advanced-dashboard-subtitle'}>
                                            Total spend distribution per supplier
                                        </Typography>
                                        <Divider/>
                                        <AdvancedBarChart
                                            dataWrapper={asd}
                                            selectedSuppliers={bagStore.advanced.filters.s_ids}
                                            supplierFilterChanged={bagStore.advanced.groupParentSuppliersFilter ? bagStore.advanced.parentSupplierFilterChanged : bagStore.advanced.supplierFilterChanged}
                                            columnBreakDown={bagStore.advanced.spendDonutDataSplited.length}
                                            loading={bagStore.advanced.advancedSpendConcentrationDataSplitedRMs[index].busy}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>)}
                        <Grid item xs={12} className="advanced-dashboard-chart full-width-bubble-map">
                            <BubbleMap
                                data={bagStore.advanced.geoDataSplited}
                                countryFilterChanged={bagStore.advanced.countryFilterChanged}
                                selectedCountries={bagStore.advanced.filters.country}
                            />
                        </Grid>
                    </Grid>}
                </Grid>

                <section className="advanced-looker-tables">
                    <Typography variant={"h4"} className="advanced-dashboard-title my-3">
                        Category specific opportunities
                        {p.p.enableParentSupplierAdvDashboard && synergyStore.isSynergyDatabag &&
                            <Typography variant="caption" className="advanced-dashboard-title mx-4">
                                <Switch
                                    checked={bagStore.advanced.filters.only_common_categories ?? false}
                                    onChange={(_e, checked: boolean) => {
                                        bagStore.advanced.setOnlyCommonCategoriesFilter(checked)
                                    }}/>
                                Only common categories
                            </Typography>}
                    </Typography>
                    <AdvancedTable
                        className="advanced-opportunity-table"
                        headers={OPPORTUNITY_HEADERS}
                        dataWrapper={bagStore.advanced.opportunityData}
                        breakdownFilterChanged={bagStore.advanced.breakdownFilterChanged}
                        ordering={bagStore.advanced.opportunity_ordering}
                        loading={bagStore.advanced.advancedOpportunityDataRM.busy}
                        // TODO CAT-2266: Pivot should be on customized columns
                        // rowSpan
                    />

                    {!DISABLE_TWO_GRAPHS &&
                        <section className="advanced-line-graph-container">
                            <Card>
                                <CardContent>
                                    <Typography variant="h6">
                                        Spend per supplier
                                    </Typography>
                                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom
                                                className="advanced-dashboard-subtitle">
                                        Analyzing trends in various categories
                                    </Typography>
                                    <Divider className="my-1"/>
                                    <Box mt={2}>
                                        <LineChart dataWrapper={bagStore.advanced.spendPerSupplierData}/>
                                    </Box>
                                </CardContent>
                            </Card>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6">
                                        Spend per level one category
                                    </Typography>
                                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom
                                                className={'advanced-dashboard-subtitle'}>
                                        Analyzing trends in specific levels
                                    </Typography>
                                    <Divider className="my-1"/>
                                    <Box mt={2}>
                                        <LineChart dataWrapper={bagStore.advanced.spendPerActiveL1Data}/>
                                    </Box>
                                </CardContent>
                            </Card>
                        </section>
                    }

                    <div className="advanced-supplier-breakdown-table">
                        <Typography variant="h4" className="advanced-dashboard-title my-3">
                            Supplier and spend category overview
                            {p.p.enableParentSupplierAdvDashboard && synergyStore.isSynergyDatabag &&
                                <Typography variant="caption" className="advanced-dashboard-title mx-4">
                                    <Switch
                                        checked={bagStore.advanced.filters.only_common_suppliers ?? false}
                                        onChange={(_e, checked: boolean) => {
                                            bagStore.advanced.setOnlyCommonSuppliersFilter(checked)
                                        }}/>
                                    Only common suppliers
                                </Typography>}
                        </Typography>
                        <AdvancedTable
                            className="advanced-supplier-breakdown-table"
                            headers={SUP_BREAKDOWN_HEADERS}
                            dataWrapper={bagStore.advanced.supplierBreakdownData}
                            breakdownFilterChanged={bagStore.advanced.breakdownFilterChanged}
                            ordering={bagStore.advanced.sup_breakdown_ordering}
                            loading={bagStore.advanced.advancedSupplierBreakdownDataRM.busy}
                            // TODO CAT-2266: Pivot should be on customized columns
                            // rowSpan
                        />
                    </div>
                </section>
            </Grid>
            <Grid item xs={1}></Grid>
        </Grid>
    </KoiFacade>
});
