import {generatePath, Location} from "react-router-dom";
import {AnyRouteMatch, DatabagRouteMatch, routes} from "./routes";
import {environment} from "../env";
import {AppState, DEFAULT_APP_STATE} from "../stores/RootAppStore";
import {getDashboardEnv} from "../pages/multi-dashboard/merge-x-cleanup-dashboard/constants";
import {C} from "../configurations/active-configuration";

/**
 * TODO CAT-712
 * @deprecated Deprecate this function in favor of DashboardEnvironment
 */
export type AppEnvironment = 'synergy_v1'
    | 'taxonomy_merge'
    | 'default'
    | 'single_cleansing' | 'single_analytics' | 'synergy_v3' | 'synergy_merge'
    | 'pic'

/**
 * TODO CAT-712
 * @deprecated Deprecate this function in favor of DashboardEnvironment
 */
export function getAppEnvironment(location: Location): AppEnvironment {
    const pageUrl: string = location.pathname;
    switch (pageUrl) {
        case routes.home:
        case routes.single_data_home2:
        case routes.single_data_home:
        case routes.single_data_manage:
        case routes.single_cleansing_dashboard:
        case routes.single_cleansing_sup_nor:
        case routes.single_cleansing_cat_welcome:
        case routes.single_cleansing_cat_busy:
        case routes.single_cleansing_cat_review:
        case routes.single_cleansing_cat_result:
        case routes.single_cleansing_taxonomy_builder:
        case routes.single_cleansing_taxonomy_builder_id:
        case routes.merge_x_taxonomy_suggestor_id:
        case routes.merge_x_taxonomy_suggestor:
        case routes.merge_x_taxonomy_builder_id:
        case routes.merge_x_taxonomy_builder:
            return 'single_cleansing';
        case routes.single_analytics_dashboard:
        case routes.single_analytics_koi_ss:
        case routes.single_analytics_koi_kdf:
        case routes.single_analytics_koi_ppv:
        case routes.single_analytics_koi_sc:
        case routes.single_data_opportunities:    // dubious
        case routes.single_data_opportunities_id: // dubious
        case routes.single_data_report:           // dubious
        case routes.single_looker_preview:
            return 'single_analytics';
        case routes.synergy_v3:
        case routes.synergy_v3_data:
        case routes.synergy_v3_dashboard:
        case routes.synergy_v3_suppliers:
        case routes.synergy_v3_categories:
        case routes.synergy_v3_spend:
        case routes.synergy_v3_opportunities:    // dubious
        case routes.synergy_v3_opportunities_id: // dubious
        case routes.synergy_v3_report:           // dubious
            return 'synergy_v3';
        case routes.synergy_merge_dashboard:
        case routes.synergy_merge_taxonomy_viewer:
        case routes.synergy_merge_taxonomy_viewer_id:
        case routes.synergy_merge_taxonomy_builder:
        case routes.synergy_merge_taxonomy_builder_id:
        case routes.synergy_merge_review:
        case routes.synergy_merge_review_result:
            return 'synergy_merge';
        case routes.job_synergy_v1_0:
        case routes.job_synergy_v1_1:
        case routes.job_synergy_v1_2:
        case routes.job_synergy_v1_dashboard:
        case routes.job_synergy_v1_suppliers:
        case routes.job_synergy_v1_categories:
        case routes.job_synergy_v1_opportunities:
            return 'synergy_v1';
        case routes.synergy_v2:
        case routes.synergy_v2_data:
        case routes.synergy_v2_dashboard:
        case routes.synergy_v2_taxonomy_builder_id:
        case routes.synergy_v2_taxonomy_viewer:
        case routes.synergy_v2_taxonomy_viewer_id:
        case routes.synergy_v2_review:
        case routes.synergy_v2_review_result:
        case routes.synergy_v2_opportunities:
            return 'taxonomy_merge';
        default:
            return 'default';
    }
}

export function lookupHomePath(location: Location, state?: AppState, logo?: boolean): string {
    // In case that features that are accessible from home are enabled, regardless of package home should be returned
    if (logo &&
        (C.profile.enableDataIngestion || C.profile.enableTaxonomyIngestion || C.profile.enableSavingInitiativeTracker)
    ) {
        return routes.home;
    }
    if (environment.package === 'merge_1') {
        return routes.home;
    }
    if (environment.package === 'cleanup') {
        if (state?.dataType === 'no_data') {
            return routes.home;
        }
        return routes.cleanup_dashboard;
    }
    if (environment.package === 'merge_x_cleanup') {
        const dashboardEnv = state ? getDashboardEnv(state) : null;
        // console.log('lookupHomePath', toJS(state), dashboardEnv);
        if (state) {
            if (state.dataType === 'no_data') {
                return routes.home;
            }
            if (dashboardEnv === 'synergy') {
                return routes.merge_xc_combined_dashboard;
            }
        }
        return routes.merge_xc_single_dashboard;
    }
    if (environment.package === 'merge_x') {
        if (!state) {
            console.error('State is not defined in package ' + environment.package);
            return routes.merge_x_dashboard;
        }
        switch (state.dashboardEnvironment) {
            case 'cleanup':
                return routes.merge_x_cleanup_dashboard;
            case 'analytics':
                return routes.merge_x_analytics_dashboard;
            case 'merge':
                return routes.merge_x_merge_dashboard;
            // case 'master':
            //     console.log('master')
            //     return routes.merge_x_master_dashboard;
            default:
                // return routes.merge_x_dashboard;
                //FIXME: THIS IS A TEMPORARY FIX FOR THE MASTER DASHBOARD, BUT IF WE WILL ADD ANOTHER ONE WE NEED TO CHANGE THIS
                //THE PROBLEMS IS THAT THE CASE 'master' IS NOT REACHED
                return routes.merge_x_master_dashboard;
        }
    }
    if (environment.package === 'merge_2') {
        if (!state) {
            console.error('State is not defined in package ' + environment.package);
            return routes.merge_2_dashboard;
        }
        switch (state.dashboardEnvironment) {
            case 'cleanup':
                return routes.merge_2_cleanup_dashboard;
            case 'analytics':
                return routes.merge_2_analytics_dashboard;
            case 'merge':
                return routes.merge_2_merge_dashboard;
            // case 'master':
            //     console.log('master')
            //     return routes.merge_2_master_dashboard;
            default:
                // TODO CAT 2231: Default route for now should be cleanup. Needs further investigation why the
                //  following happens
                //THE PROBLEMS IS THAT THE CASE 'master' IS NOT REACHED
                return routes.merge_2_cleanup_dashboard;
        }
    }
    if (environment.package === 'sales_demo') {
        switch (state?.dashboardEnvironment) {
            default: // Not configured environments
                console.debug('lookupHomePath() Not configured environment', state?.dashboardEnvironment)
                return routes.home;
            case 'data-ingestion':
            case 'approval':
                return routes.home;
            case undefined: // Data selector, e.g. start page
            case 'cleanup':
                return routes.demo_cleanup_dashboard;
            case 'analytics':
                return routes.demo_cleanup_analytics_dashboard;
            case 'synergy':
                return routes.demo_synergy_analytics_dashboard;
            case 'merge':
                return routes.demo_merge_dashboard;
        }
    }

    // TODO CAT-712
    switch (location.pathname) {
        // The routes that cannot go to the dashboard have different behavior
        case routes.job_synergy_v1_0:
            return routes.job_synergy_v1_0
        case routes.job_synergy_v1_1:
            return routes.job_synergy_v1_1
        case routes.synergy_v2:
            return routes.synergy_v2
        case routes.approval:
            // Environment where there is no bag present
            return '/'
        case routes.approval_cat_detail:
        case routes.approval_tax_detail:
            // Special case
            return routes.approval;
        default:
            // The rest goes to the dashboard

            // DPW Demo: all single_cleansing_dashboard
            return routes.single_cleansing_dashboard;
    }
}

/**
 * Generates a path to the home page of the current environment, if a certain ID is present in the URL
 * @param location
 * @param params to override the params
 * @param state
 */
export function generateHomePath<M extends AnyRouteMatch>(location: Location<M>, params?: M, state?: AppState) {
    const path = lookupHomePath(location, state, params && params.hasOwnProperty('logo'));
    return generatePath(path, params);
}

export function generateDashboardPath<M extends AnyRouteMatch>(location: Location<M>, params?: M, state?: AppState) {
    const s: AppState = state ? {...state} : {...DEFAULT_APP_STATE};
    s.dataType = 'default';
    const path = lookupHomePath(location, s);
    return generatePath(path, params);
}

export function generateGotoTaxonomyBuilderPath(databagId: number, taxonomyId?: number) {
    if (environment.package === 'merge_1') {
        if (taxonomyId) {
            return generatePath(routes.merge_1_client_taxonomy_builder_id, {id: databagId, taxonomyId: taxonomyId});
        } else {
            return generatePath(routes.merge_1_client_taxonomy_builder, {id: databagId});
        }
    }
    if (environment.package === 'merge_2') {
        if (taxonomyId) {
            return generatePath(routes.merge_2_taxonomy_builder_id, {id: databagId, taxonomyId: taxonomyId});
        } else {
            return generatePath(routes.merge_2_taxonomy_builder, {id: databagId});
        }
    }
    // if (environment.package === 'merge_x_cleanup') {}
    if (environment.package === 'merge_x') {
        if (taxonomyId) {
            return generatePath(routes.merge_x_taxonomy_builder_id, {id: databagId, taxonomyId: taxonomyId});
        } else {
            return generatePath(routes.merge_x_taxonomy_builder, {id: databagId});
        }
    }
    if (environment.package === 'cleanup') {
        if (taxonomyId) {
            return generatePath(routes.taxonomy_builder_id, {id: databagId, taxonomyId: taxonomyId});
        } else {
            return generatePath(routes.taxonomy_builder, {id: databagId});
        }
    }
    if (environment.package === 'sales_demo') {
        if (taxonomyId) {
            return generatePath(routes.demo_cleanup_taxonomy_builder_id, {id: databagId, taxonomyId: taxonomyId});
        } else {
            return generatePath(routes.demo_cleanup_taxonomy_builder, {id: databagId});
        }
    }

    if (taxonomyId) {
        return generatePath(routes.job_taxonomy_builder_id, {id: databagId, taxonomyId: taxonomyId});
    }
    return generatePath(routes.job_taxonomy_builder, {id: databagId});
}

export function generateGotoTaxonomySuggestor(databagId: number, taxonomyId: number) {
    if (environment.package === 'merge_x') {
        return generatePath(routes.merge_x_taxonomy_suggestor, {id: databagId, taxonomyId: taxonomyId});
    }
    if (environment.package === 'cleanup') {
        return generatePath(routes.taxonomy_suggestor, {id: databagId, taxonomyId: taxonomyId});
    }
    return generatePath(routes.job_taxonomy_suggestor, {id: databagId, taxonomyId: taxonomyId});
}

export function generateGotoDashboardPath<M extends AnyRouteMatch>(location: Location<M>, params?: M, state?: AppState) {
    if (environment.package === 'merge_1') {
        return generateHomePath(location, params, state);
    }
    if (environment.package === 'cleanup') {
        return generateHomePath(location, params, state);
    }
    if (environment.package === 'merge_x_cleanup') {
        return generateHomePath(location, params, state);
    }
    if (environment.package === 'merge_x') {
        return generateHomePath(location, params, state);
    }
    if (environment.package === 'merge_2') {
        return generateHomePath(location, params, state);
    }
    if (environment.package === 'sales_demo') {
        if (state?.dashboardEnvironment === 'data-ingestion') {
            return generatePath(routes.demo_cleanup_dashboard, params)
        }
        return generateHomePath(location, params, state);
    }

    // TODO CAT-712
    // The rest goes to the dashboard
    let url;
    switch (getAppEnvironment(location)) {
        case "synergy_v1":
            url = routes.job_synergy_v1_dashboard;
            break;
        case "taxonomy_merge":
            url = routes.synergy_v2_dashboard;
            break;
        case "default":
            url = routes.job_dashboard;
            break;
        case "single_cleansing":
        case "single_analytics":
        case "synergy_v3":
        case "synergy_merge":
            return generateHomePath(location, params);
        default:
            url = '/';
            break;
    }
    console.log('generateGotoDashboardPath', url);
    return generatePath(url, params);
}

export function generateIndividualCategorizationReviewPath(databag: number): string {
    const params: DatabagRouteMatch = {id: String(databag)};
    if (environment.package === 'merge_1') {
        return generatePath(routes.merge_1_client_categorization_review, params);
    }
    if (environment.package === 'merge_2') {
        return generatePath(routes.merge_2_cleanup_categorization, params);
    }
    if (environment.package === 'merge_x') {
        return generatePath(routes.merge_x_cleanup_categorization, params);
    }
    if (environment.package === 'merge_x_cleanup') {
        return generatePath(routes.merge_xc_single_categorization, params);
    }
    if (environment.package === 'cleanup') {
        return generatePath(routes.categorization_review, params);
    }
    // fallback
    return generatePath(routes.single_cleansing_cat_review, params);
}

/**
 * @see https://reactrouter.com/en/main/start/concepts#locations
 */
export function locationToURL(location: Location): string {
    return location.pathname + location.search + location.hash;
}
