import React from "react";
import {observer} from "mobx-react-lite";
import {
    Alert,
    Button,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField, Tooltip, Typography
} from "@mui/material";
import {Add, Check, Edit} from "@mui/icons-material";
import {useSitStores} from "../../../../stores";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Trash} from "lucide-react";
import moment from "moment/moment";

export const InitiativeSuccessStepFrom: React.FC = observer(() => {
    const {initiativeInstanceStore} = useSitStores();

    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <Table>
                <TableBody>
                    {initiativeInstanceStore.ss_c.map((ssc) =>
                        <TableRow key={ssc.index}>{!ssc.editing ? <>
                            <TableCell width="10">
                                <Checkbox
                                    disabled={initiativeInstanceStore.disabled}
                                    checked={ssc.instance.achieved}
                                    onChange={(_event) => {
                                        ssc.toggleAchieved();
                                    }}/>
                            </TableCell>
                            <TableCell>
                                <Tooltip title={ssc.instance.description}>
                                    <Typography variant="h6" component="span">{ssc.instance.title}</Typography>
                                </Tooltip>
                            </TableCell>
                            <TableCell width="50">{moment(ssc.instance.due_date).format('L')}</TableCell>
                            <TableCell width="10">
                                <IconButton
                                    disabled={initiativeInstanceStore.disabled ||
                                        initiativeInstanceStore.ss_c.filter(c => c.editing).length > 0}
                                    onClick={() => ssc.startEditing()}>
                                    <Edit/>
                                </IconButton>
                            </TableCell>
                            <TableCell width="10">
                                <IconButton
                                    disabled={initiativeInstanceStore.disabled}
                                    onClick={() => {
                                        initiativeInstanceStore.removeSuccessStep(ssc.index);
                                    }}>
                                    <Trash/>
                                </IconButton>
                            </TableCell>
                        </> : <TableCell colSpan={5}><Grid container spacing={3}>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        disabled={initiativeInstanceStore.disabled}
                                        variant="standard"
                                        required
                                        label="Milestone Title"
                                        value={ssc.instance.title}
                                        onChange={(event) => {
                                            ssc.title = event.target.value;
                                        }}
                                    />
                                </FormControl>
                                {ssc.w_title && <Alert severity="error" color="error">{ssc.w_title}</Alert>}
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            disabled={initiativeInstanceStore.disabled}
                                            label="Milestone Due date"
                                            value={ssc.dueDate}
                                            onChange={(value) => {
                                                if (value !== null)
                                                    ssc.dueDate = value;
                                            }}/>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        disabled={initiativeInstanceStore.disabled}
                                        multiline minRows={3}
                                        variant="outlined"
                                        label="Milestone Description"
                                        value={ssc.instance.description ?? ''}
                                        onChange={(event) =>
                                            ssc.description = event.target.value
                                        }/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    disabled={initiativeInstanceStore.disabled}
                                    variant="outlined"
                                    className="float-end ms-4"
                                    startIcon={<Check/>}
                                    onClick={() => ssc.doneEditing()}
                                >Done</Button>
                                <Button
                                    disabled={initiativeInstanceStore.disabled}
                                    variant="outlined"
                                    color="warning"
                                    className="float-end"
                                    startIcon={<Trash/>}
                                    onClick={() => initiativeInstanceStore.removeSuccessStep(ssc.index)}
                                >Remove</Button>
                            </Grid>
                        </Grid></TableCell>}
                        </TableRow>
                    )}
                    {
                        (initiativeInstanceStore.ss_c.length === 0 ||
                            (!initiativeInstanceStore.ss_c[initiativeInstanceStore.ss_c.length - 1].isNew &&
                                initiativeInstanceStore.ss_c.filter(c => c.editing).length === 0)) &&
                        <TableRow>
                            <TableCell colSpan={5}>
                                <Button
                                    disabled={initiativeInstanceStore.disabled}
                                    fullWidth
                                    variant="outlined"
                                    startIcon={<Add/>}
                                    onClick={() => initiativeInstanceStore.addSuccessStep()}
                                >Key Milestone</Button>
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </Grid>
    </Grid>
});