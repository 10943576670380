import {Bag, JobInputIndex} from "../services/classes/Bag";

export type DatabagRouteMatch = { id: string };
export type DataFileRouteMatch = { dataFileId: string, };
export type TaxonomyRouteMatch = { id: string, taxonomyId: string };
export type CmpDataBagRouteMatch = { id: string, id2: string };
export type ApprovalRouteMatch = { approvalId: string };
export type OpportunityRouteMatch = { id: string, oppId: string };
export type MithraHistoryState = undefined | {
    bag: Bag,
    bag2?: Bag,
    selected_job_input: JobInputIndex, // Not used yet
};
export type TaxonomySuggestorRouteMatch = { id: string, taxonomyId: string, taxonomySuggestorId?: string, };
export type AnyRouteMatch =
    DatabagRouteMatch
    | DataFileRouteMatch
    | TaxonomyRouteMatch
    | CmpDataBagRouteMatch
    | ApprovalRouteMatch
    | TaxonomySuggestorRouteMatch
    ;

export const routes = {
    home: '/',
    login: '/login',

    // Links without any bag id's
    merge_1_supplier_normalization: '/m1/supplier-normalization',
    merge_1_supplier_normalization_master: '/m1/master/supplier-normalization',
    merge_1_client_categorization_review: '/m1/categorization',
    merge_1_client_taxonomy_builder: '/m1/taxonomy-builder',
    merge_1_client_taxonomy_builder_id: '/m1/taxonomy-builder/:taxonomyId',
    merge_1_common_suppliers: '/m1/common-suppliers',
    merge_1_common_categories: '/m1/common-categories',
    merge_1_common_categories_review: '/m1/common-categories/review',

    job_koi_sc: '/job/:id/spend-concentration',
    job_koi_cfo: '/job/:id/cash-flow-optimization',
    job_koi_ss: '/job/:id/supplier-segmentation',
    job_koi_ppv: '/job/:id/price-variance',
    job_koi_kdf: '/job/:id/categories',
    job_koi_sh: '/job/:id/supplier-hierarchy',
    job_supplier_normalization: '/job/:id/supplier-normalization',

    job_ai: '/job/:id/automatic-classification',

    job_dashboard: '/job/:id/dashboard',
    job_old_dashboard: '/job/:id/dashboard-old',
    job_advanced_dashboard: '/job/:id/advanced',
    job_business_case_report: '/job/:id/business-case-report',
    job_business_case_report_id: '/job/:id/business-case-report/:businessCaseId',
    job_koi_bu: '/job/:id/koi-3',
    job_manage: '/job/:id/manage',

    job_synergy_v1_0: '/synergy-v1',
    job_synergy_v1_1: '/synergy-v1/:id/data',
    job_synergy_v1_2: '/synergy-v1/:id/:id2',
    job_synergy_v1_opportunities: '/synergy-v1/:id/:id2/opportunities',
    job_synergy_v1_dashboard: '/synergy-v1/:id/:id2/dashboard',
    job_synergy_v1_suppliers: '/synergy-v1/:id/:id2/suppliers',
    job_synergy_v1_categories: '/synergy-v1/:id/:id2/categories',

    synergy_v2: '/synergy-v2',
    synergy_v2_data: '/synergy-v2/:id/data',
    synergy_v2_dashboard: '/synergy-v2/:id/dashboard',
    synergy_v2_taxonomy_builder_id: '/synergy-v2/:id/taxonomy-builder/:taxonomyId',
    synergy_v2_taxonomy_viewer: '/synergy-v2/:id/taxonomy-viewer',
    synergy_v2_taxonomy_viewer_id: '/synergy-v2/:id/taxonomy-viewer/:taxonomyId',
    synergy_v2_review: '/synergy-v2/:id/categorization',
    synergy_v2_review_result: '/synergy-v2/:id/categorization/review',
    synergy_v2_opportunities: '/synergy-v2/:id/opportunity_v2',

    koi_opportunities: '/job/:id/opportunities',
    demo_opportunities: '/job/:id/demo_opportunities',
    ppv_opportunities_detail: '/job/:id/opportunities/ppv/:oppId',

    job_ai_cat_welcome: '/job/:id/categorization',
    job_ai_cat_busy: '/job/:id/categorization/wait',
    job_ai_cat_review: '/job/:id/categorization/review',
    job_ai_cat_result: '/job/:id/categorization/result',

    job_taxonomy_builder: '/job/:id/taxonomy-builder',
    job_taxonomy_builder_id: '/job/:id/taxonomy-builder/:taxonomyId',
    job_taxonomy_suggestor: '/job/:id/taxonomy-suggestor/:taxonomyId/:taxonomySuggestorId',

    job_report: '/job/:id/report',

    approval: '/approval',
    approval_cat_detail: '/approval/category/:approvalId',
    approval_tax_detail: '/approval/taxonomy/:approvalId',

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Cleanup dashboard urls
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    cleanup_dashboard: '/c/:id/dashboard',
    supplier_normalization: '/c/:id/supplier-normalization',
    // categorization_welcome: '/c/:id/categorization',
    // categorization_busy: '/c/:id/categorization/wait',
    categorization_review_2: '/c/:id/categorization/revision-hack/:revisionHackParam', // Used only for internal review
    categorization_review: '/c/:id/categorization',
    categorization_statistics: '/c/:id/categorization/statistics',
    // categorization_result: '/c/:id/categorization/result',
    taxonomy_builder: '/c/:id/taxonomy-builder',
    taxonomy_builder_id: '/c/:id/taxonomy-builder/:taxonomyId',
    taxonomy_suggestor: '/c/:id/synergy/taxonomy-suggestor/:taxonomyId',
    koi_spend_concentration: '/c/:id/spend-concentration',
    data_manage: '/c/:id/data',
    data_home: '/c',
    koi_ppv: '/c/:id/price-variance',

    cleanup_contract_insights: '/c/:id/contract-insights',

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Demo urls
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    demo_cleanup_dashboard: '/d/:id/cleanup/dashboard',
    demo_cleanup_supplier_normalization: '/d/:id/cleanup/supplier-normalization',
    demo_cleanup_categorization: '/d/:id/cleanup/categorization',
    demo_cleanup_taxonomy_builder: '/d/:id/cleanup/taxonomy-builder',
    demo_cleanup_taxonomy_builder_id: '/d/:id/cleanup/taxonomy-builder/:taxonomyId',
    demo_cleanup_taxonomy_health_check: '/d/:id/cleanup/taxonomy-builder/health-check',
    demo_cleanup_taxonomy_suggestion_v1: '/d/:id/cleanup/taxonomy-builder/suggestion-single',
    demo_cleanup_taxonomy_suggestion_v2: '/d/:id/cleanup/taxonomy-builder/suggestion',
    demo_cleanup_opportunities: '/d/:id/cleanup/opportunities',
    demo_cleanup_golden_record: '/d/:id/cleanup/golden-record',

    demo_cleanup_analytics_dashboard: '/d/:id/analytics/dashboard',
    demo_cleanup_analytics_koi_ss: '/d/:id/analytics/supplier-segmentation',
    demo_cleanup_analytics_koi_kdf: '/d/:id/analytics/categories',
    demo_cleanup_analytics_koi_ppv: '/d/:id/analytics/price-variance',
    demo_cleanup_analytics_koi_sc: '/d/:id/analytics/spend-concentration',
    demo_cleanup_analytics_contract_insights: '/d/:id/analytics/contract-insights',
    demo_cleanup_analytics_contract_analytics: '/d/:id/analytics/:contract/contract-analytics',
    demo_cleanup_analytics_contract_opportunities: '/d/:id/analytics/:contract/contract-opportunities',
    demo_cleanup_analytics_adv_dashboard: '/d/:id/analytics/advanced-dashboard',

    demo_synergy_analytics_dashboard: '/d/:id/synergy/dashboard',
    demo_synergy_suppliers: '/d/:id/synergy/suppliers',
    demo_synergy_categories: '/d/:id/synergy/categories',
    demo_synergy_spend: '/d/:id/synergy/spend',

    demo_merge_dashboard: '/d/:id/merge/dashboard',
    demo_merge_taxonomy_viewer: '/d/:id/merge/taxonomy-viewer',
    demo_merge_taxonomy_viewer_id: '/d/:id/merge/taxonomy-viewer/:taxonomyId',
    demo_merge_taxonomy_builder: '/d/:id/merge/taxonomy-builder',
    demo_merge_taxonomy_builder_id: '/d/:id/merge/taxonomy-builder/:taxonomyId',
    demo_merge_taxonomy_suggester: '/d/:id/merge/taxonomy-suggester',
    demo_merge_review: '/d/:id/merge/categorization',
    demo_merge_review_result: '/d/:id/merge/categorization/review',
    demo_merge_taxonomy_mapper: '/d/:id/merge/taxonomy-mapper',
    demo_merge_taxonomy_mapper_review: '/d/:id/merge/taxonomy-mapper/review',
    demo_merge_cat_review: '/d/:id/merge/categorization',

    demo_synergy_opportunities: '/d/synergy/opportunity',
    demo_synergy_opportunities_id: '/d/:id/synergy/opportunity',

    demo_synergy_report: '/d/:id/synergy/report',

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Merge urls
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // merge_2: Liberty Global
    merge_2_dashboard: '/m2/:id/dashboard',
    merge_2_cleanup_dashboard: '/m2/:id/cleanup/dashboard',
    merge_2_cleanup_taxonomy_viewer: '/m2/:id/cleanup/taxonomy',
    merge_2_cleanup_taxonomy_viewer_id: '/m2/:id/cleanup/taxonomy/:taxonomyId',
    merge_2_cleanup_supplier_normalization: '/m2/:id/cleanup/supplier-normalization',
    merge_2_cleanup_categorization: '/m2/:id/cleanup/categorization',
    merge_2_cleanup_categorization_statistics: '/m2/:id/cleanup/categorization-statistics',

    merge_2_analytics_dashboard: '/m2/:id/analytics/dashboard',
    merge_2_analytics_supplier_segmentation: '/m2/:id/analytics/supplier-segmentation',
    merge_2_analytics_ppv: '/m2/:id/analytics/purchase-price-variance',
    merge_2_analytics_spend_concentration: '/m2/:id/analytics/spend-concentration',
    merge_2_analytics_explore_categories: '/m2/:id/analytics/explore-categories',

    // Decided to go with Synergy for now
    merge_2_merge_dashboard: '/m2/:id/merge/dashboard',
    merge_2_merge_taxonomy_mapper_baseline_1: '/m2/:id/merge/taxonomy/mapper_1/baseline',
    merge_2_merge_taxonomy_mapper_target_1: '/m2/:id/merge/taxonomy/mapper_1',
    merge_2_merge_taxonomy_mapper_baseline_2: '/m2/:id/merge/taxonomy/mapper_2/baseline',
    merge_2_merge_taxonomy_mapper_target_2: '/m2/:id/merge/taxonomy/mapper_2',
    merge_2_merge_taxonomy_mapper_baseline_3: '/m2/:id/merge/taxonomy/mapper_3/baseline',
    merge_2_merge_taxonomy_mapper_target_3: '/m2/:id/merge/taxonomy/mapper_3',
    merge_2_merge_categorization: '/m2/:id/merge/categorization',
    merge_2_merge_opportunity: '/m2/:id/merge/opportunity',

    merge_2_taxonomy_builder: '/m2/:id/synergy/taxonomy-builder',
    merge_2_taxonomy_builder_id: '/m2/:id/synergy/taxonomy-builder/:taxonomyId',

    merge_2_merge_synergy_overview: '/m2/:id/synergy/overview',
    merge_2_merge_common_suppliers: '/m2/:id/synergy/common-suppliers',
    merge_2_merge_common_categories: '/m2/:id/synergy/common-categories',
    merge_2_merge_spend: '/m2/:id/synergy/common-spend',

    // The Target and Baseline taxonomy mappers were used for LG
    merge_2_merge_taxonomy_mapper_baseline: '/m2/:id/synergy/taxonomy/mapper/baseline',
    merge_2_merge_taxonomy_mapper_target: '/m2/:id/synergy/taxonomy/mapper',
    merge_2_master_taxonomy_viewer_baseline: '/m2/:id/master/taxonomy/baseline',
    merge_2_master_taxonomy_builder_target: '/m2/:id/master/taxonomy',

    merge_2_master_dashboard: '/m2/:id/master/dashboard',
    merge_2_master_taxonomy_builder: '/m2/:id/master/taxonomy/:taxonomyId',
    merge_2_master_taxonomy_viewer: '/m2/:id/master/taxonomy/:taxonomyId/view',
    merge_2_master_taxonomy_compare_un: '/m2/:id/master/taxonomy/compare/un',
    merge_2_master_taxonomy_compare_baseline: '/m2/:id/master/taxonomy/compare',

    merge_2_master_looker_opp_old: '/m2/:id/master/opportunity/old',
    merge_2_master_looker_opp_new: '/m2/:id/master/opportunity/new',
    merge_2_master_looker_adv_all: '/m2/:id/master/advanced/all',
    merge_2_master_looker_subsidiary_dashboard: '/m2/:id/analytics/advanced',

    // marge_x: SHV
    merge_x_dashboard: '/m/:id/dashboard',
    merge_x_cleanup_dashboard: '/m/:id/cleanup/dashboard',
    merge_x_cleanup_taxonomy_viewer: '/m/:id/cleanup/taxonomy',
    merge_x_cleanup_taxonomy_viewer_id: '/m/:id/cleanup/taxonomy/:taxonomyId',
    merge_x_cleanup_supplier_normalization: '/m/:id/cleanup/supplier-normalization',
    merge_x_cleanup_categorization_statistics: '/m/:id/cleanup/categorization-statistics',
    merge_x_cleanup_categorization: '/m/:id/cleanup/categorization',
    merge_x_cleanup_categorization_rev_hack: '/m/:id/cleanup/categorization/revision-hack/:revisionHackParam',

    merge_x_analytics_dashboard: '/m/:id/analytics/dashboard',
    merge_x_analytics_supplier_segmentation: '/m/:id/analytics/supplier-segmentation',
    merge_x_analytics_ppv: '/m/:id/analytics/purchase-price-variance',
    merge_x_analytics_spend_concentration: '/m/:id/analytics/spend-concentration',
    merge_x_analytics_explore_categories: '/m/:id/analytics/explore-categories',
    merge_x_analytics_opportunity_dashboard: '/m/:id/analytics/opportunity',

    // Decided to go with Synergy for now
    merge_x_merge_dashboard: '/m/:id/synergy/dashboard',
    merge_x_merge_taxonomy_mapper_baseline_1: '/m/:id/synergy/taxonomy/mapper_1/baseline',
    merge_x_merge_taxonomy_mapper_target_1: '/m/:id/synergy/taxonomy/mapper_1',
    merge_x_merge_taxonomy_mapper_baseline_2: '/m/:id/synergy/taxonomy/mapper_2/baseline',
    merge_x_merge_taxonomy_mapper_target_2: '/m/:id/synergy/taxonomy/mapper_2',
    merge_x_merge_taxonomy_mapper_baseline_3: '/m/:id/synergy/taxonomy/mapper_3/baseline',
    merge_x_merge_taxonomy_mapper_target_3: '/m/:id/synergy/taxonomy/mapper_3',
    merge_x_merge_categorization: '/m/:id/synergy/categorization',

    merge_x_taxonomy_builder: '/m/:id/synergy/taxonomy-builder',
    merge_x_taxonomy_builder_id: '/m/:id/synergy/taxonomy-builder/:taxonomyId',
    merge_x_taxonomy_suggestor_id: '/m/:id/synergy/taxonomy-suggestor/:taxonomyId/:taxonomySuggestorId',
    merge_x_taxonomy_suggestor: '/m/:id/synergy/taxonomy-suggestor/:taxonomyId',
    merge_x_taxonomy_health_check: '/m/:id/synergy/taxonomy-health-check',

    merge_x_merge_common_suppliers: '/m/:id/synergy/common-suppliers',
    merge_x_merge_common_categories: '/m/:id/synergy/common-categories',
    merge_x_merge_spend: '/m/:id/synergy/common-spend',
    merge_x_merge_advanced_dashboard: '/m/:id/synergy/advanced',

    // The Target and Baseline taxonomy mappers were used for LG
    merge_x_merge_taxonomy_mapper_baseline: '/m/:id/synergy/taxonomy/mapper/baseline',
    merge_x_merge_taxonomy_mapper_target: '/m/:id/synergy/taxonomy/mapper',
    merge_x_master_taxonomy_viewer_baseline: '/m/:id/master/taxonomy/baseline',
    merge_x_master_taxonomy_builder_target: '/m/:id/master/taxonomy',

    merge_x_master_dashboard: '/m/:id/master/dashboard',
    merge_x_master_taxonomy_builder: '/m/:id/master/taxonomy/:taxonomyId',
    merge_x_master_taxonomy_viewer: '/m/:id/master/taxonomy/:taxonomyId/view',
    merge_x_master_taxonomy_compare_un: '/m/:id/master/taxonomy/compare/un',
    merge_x_master_taxonomy_compare_baseline: '/m/:id/master/taxonomy/compare',

    merge_x_master_looker_opp_old: '/m/:id/master/opportunity/old',
    merge_x_master_looker_opp_new: '/m/:id/master/opportunity/new',
    merge_x_master_looker_adv_all: '/m/:id/master/advanced/all',
    merge_x_master_looker_subsidiary_dashboard: '/m/:id/analytics/advanced',

    /**
     * Merge X Cleanup Dashboard
     */

    merge_xc_single_dashboard: '/s/:id/dashboard',
    merge_xc_single_taxonomy_builder: '/s/:id/taxonomy',
    merge_xc_single_taxonomy_builder_id: '/s/:id/taxonomy/:taxonomyId',
    merge_xc_single_categorization: '/s/:id/categorization',
    merge_xc_single_categorization_statistics: '/s/:id/categorization-statistics',
    merge_xc_single_categorization_rev_hack: '/s/:id/categorization/revision-hack/:revisionHackParam',
    merge_xc_single_advanced_dashboard: '/s/:id/opportunity',
    merge_xc_single_explore_categories: '/s/:id/explore',
    merge_xc_single_spend_concentration: '/s/:id/spend-concentration',
    merge_xc_single_ppv: '/s/:id/ppv',

    merge_xc_combined_dashboard: '/c/:id/dashboard',
    merge_xc_combined_taxonomy_builder: '/c/:id/taxonomy',
    merge_xc_combined_supplier_normalization: '/c/:id/supplier-normalization',
    merge_xc_combined_categorization: '/c/:id/categorization', // read-only
    merge_xc_combined_categorization_statistics: '/c/:id/categorization-statistics',
    merge_xc_combined_advanced_dashboard: '/c/:id/opportunity',
    merge_xc_combined_explore_categories: '/c/:id/explore',
    merge_xc_combined_spend_concentration: '/c/:id/spend-concentration',
    merge_xc_combined_ppv: '/c/:id/ppv',


    //////////////////////////
    // Single dataset views //
    //////////////////////////
    single_data_home2: '/new',
    single_data_home: '/cleanup',
    single_data_manage: '/cleanup/:id/data',

    single_cleansing_dashboard: '/cleanup/:id/dashboard',
    single_cleansing_sup_nor: '/cleanup/:id/supplier-normalization',
    single_cleansing_cat_welcome: '/cleanup/:id/categorization',
    single_cleansing_cat_busy: '/cleanup/:id/categorization/wait',
    single_cleansing_cat_review: '/cleanup/:id/categorization/review',
    single_cleansing_cat_result: '/cleanup/:id/categorization/result',
    single_cleansing_taxonomy_builder: '/cleanup/:id/taxonomy-builder',
    single_cleansing_taxonomy_builder_id: '/cleanup/:id/taxonomy-builder/:taxonomyId',

    single_analytics_dashboard: '/analytics/:id/dashboard',
    single_analytics_koi_ss: '/analytics/:id/supplier-segmentation',
    single_analytics_koi_kdf: '/analytics/:id/categories',
    single_analytics_koi_ppv: '/analytics/:id/price-variance',
    single_analytics_koi_sc: '/analytics/:id/spend-concentration',
    single_analytics_adv_dashboard: '/analytics/:id/advanced-dashboard',
    // single_analytics_koi_cfo:   '/analytics/:id/cash-flow-optimization',
    // single_analytics_koi_sh:    '/analytics/:id/supplier-hierarchy',
    single_looker_preview: '/analytics/:id/advanced-dashboard', // Deprecated

    sit_looker_preview: '/tracker', // Franke test dashboard

    single_data_opportunities: '/analytics/opportunity',
    single_data_opportunities_id: '/analytics/:id/opportunity',

    single_data_report: '/analytics/:id/report',

    ///////////////////////////
    // Synergy dataset views //
    ///////////////////////////
    synergy_v3: '/synergy',
    synergy_v3_data: '/synergy/:id/data',
    synergy_v3_dashboard: '/synergy/:id/dashboard',
    synergy_v3_suppliers: '/synergy/:id/suppliers',
    synergy_v3_categories: '/synergy/:id/categories',
    synergy_v3_spend: '/synergy/:id/spend',

    synergy_merge_dashboard: '/synergy-merge/:id/dashboard',
    synergy_merge_taxonomy_viewer: '/synergy-merge/:id/taxonomy-viewer',
    synergy_merge_taxonomy_viewer_id: '/synergy-merge/:id/taxonomy-viewer/:taxonomyId',
    synergy_merge_taxonomy_builder: '/synergy-merge/:id/taxonomy-builder', // Not used?
    synergy_merge_taxonomy_builder_id: '/synergy-merge/:id/taxonomy-builder/:taxonomyId',
    synergy_merge_review: '/synergy-merge/:id/categorization',
    synergy_merge_review_result: '/synergy-merge/:id/categorization/review',

    synergy_v3_opportunities: '/synergy/opportunity',
    synergy_v3_opportunities_id: '/synergy/:id/opportunity',

    synergy_v3_report: '/synergy/:id/report',

    synergy_overview: '/synergy/:id/overview',
    synergy_common_suppliers: '/synergy/:id/common-suppliers',

    // Functionality oriented routes
    taxonomy_mapper: '/taxonomy-mapper/:id',
    taxonomy_mapper_review: '/taxonomy-mapper/:id/review',

    looker_preview: '/advanced-dashboard',
    looker_preview_id: '/job/:id/advanced-dashboard',

    // data-ingestion
    data_ingestion: '/data-ingestion',
    data_upload_mapping: '/data-upload/:dataFileId',
    data_upload: '/data-upload',

    // taxonomy-ingestion
    taxonomy_ingestion: '/taxonomy-ingestion',
    taxonomy_upload_mapping: '/taxonomy-upload/:dataFileId',
    taxonomy_upload: '/taxonomy-upload',

    test_small_ppv_component: '/test/small-ppv-component',
    test_react_integration: '/test/react-integration',
}
