import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {
    Accordion, AccordionDetails, AccordionSummary,
    Alert, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select,
    TextField, Tooltip
} from "@mui/material";
import {useSitStores} from "../../../../stores";
import {CalendarIcon, DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Info} from "@mui/icons-material";
import PercentIcon from '@mui/icons-material/Percent';
import CalculateIcon from '@mui/icons-material/Calculate';
import {DoiStatus} from "../../../ApiTypes";
import {SitCurrency} from "../../../components/SitCurrency";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const InitiativeFinancialForm: React.FC = observer(() => {
        const {ccStore, initiativeInstanceStore} = useSitStores();
        const [doi5Open, setDoi5Open] = useState(false);

        return <Grid container spacing={3}>
            <Grid item xs={6}>
                <FormControl fullWidth margin="dense">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            disabled={initiativeInstanceStore.disabled}
                            label={"\"" + DoiStatus[5 - 1] + "\" Project Date"}
                            value={initiativeInstanceStore.doi5Date}
                            open={doi5Open}
                            slotProps={
                                // Commented out due to feedbacks in CAT-2162
                                // (!initiativeInstanceStore.creating && initiativeInstanceStore.instance.doi_status === 4
                                //     && initiativeInstanceStore.doi5SecondDate === null) ? {
                                //     textField: {
                                //         InputProps: {
                                //             endAdornment:
                                //                 <InputAdornment position="end">
                                //                     <Button
                                //                         variant="outlined"
                                //                         startIcon={<Add/>}
                                //                         onClick={() => {
                                //                             initiativeInstanceStore.doi5SecondDate = dayjs(new Date());
                                //                         }}>
                                //                         Add another doi 5
                                //                     </Button>
                                //                 </InputAdornment>
                                //         }
                                //     }
                                // } : (initiativeInstanceStore.creating ?
                                {
                                    textField: {
                                        InputProps: {
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <Tooltip title="Click to distribute project dates evenly">
                                                        <IconButton onClick={() => {
                                                            initiativeInstanceStore.distributeDois();
                                                            setDoi5Open(false);
                                                        }}>
                                                            <CalculateIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <IconButton onClick={() => setDoi5Open(!doi5Open)}>
                                                        <CalendarIcon/>
                                                    </IconButton>
                                                </InputAdornment>
                                        }
                                    }
                                }
                                // : {
                                //     textField: {
                                //         InputProps: {
                                //             endAdornment:
                                //                 <InputAdornment position="end">
                                //                     <IconButton onClick={() => setDoi5Open(!doi5Open)}>
                                //                         <CalendarIcon/>
                                //                     </IconButton>
                                //                 </InputAdornment>
                                //         }
                                //     }
                                // })
                            }
                            onChange={(value) => {
                                if (value !== null) {
                                    initiativeInstanceStore.doi5Date = value;
                                    setDoi5Open(false);
                                }
                            }}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>

            {(initiativeInstanceStore.doi5SecondDate !== null) && <Grid item xs={6}>
                <FormControl fullWidth margin="dense">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            disabled={initiativeInstanceStore.disabled}
                            label="New DOI5"
                            value={initiativeInstanceStore.doi5SecondDate}
                            onChange={(value) => {
                                if (value !== null)
                                    initiativeInstanceStore.doi5SecondDate = value;
                            }}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>}

            <Grid item xs={6}>
                <FormControl fullWidth margin="dense">
                    <TextField
                        disabled={initiativeInstanceStore.disabled}
                        id="affected_months"
                        variant="outlined"
                        label="Affected period"
                        required
                        value={initiativeInstanceStore.f_c[0].instance.affected_months ?? 0}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">months</InputAdornment>
                        }}
                        onChange={(event) =>
                            initiativeInstanceStore.f_c[0].affectedMonths = parseFloat(event.target.value) || 0
                        }/>
                </FormControl>
            </Grid>

            <Grid item xs={6}>
                <FormControl required fullWidth margin="dense">
                    <InputLabel id="currency-label">Currency</InputLabel>
                    <Select
                        disabled={initiativeInstanceStore.disabled}
                        labelId="currency-label"
                        id="currency"
                        value={!(initiativeInstanceStore.f_c[0].instance.currency_id) ? '' : initiativeInstanceStore.f_c[0].instance.currency_id}
                        label="Currency"
                        onChange={(event) => {
                            if (typeof event.target.value === 'number')
                                initiativeInstanceStore.f_c[0].currencyId = event.target.value;
                        }}>
                        {ccStore.currencyRM.result?.map((currency) => {
                            return <MenuItem
                                key={currency.id}
                                value={currency.id}>{currency.code}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                {ccStore.convertToCurrency(1, initiativeInstanceStore.f_c[0].instance.currency_id,
                        initiativeInstanceStore.profileStore.currencySymbol ?? '') === 0 &&
                    <Alert severity="error" color="error">
                        Conversion rate not found! You must add a rate for selected pair in settings.
                    </Alert>}
            </Grid>

            <Grid item xs={6}>
                <SitCurrency
                    disabled={initiativeInstanceStore.disabled}
                    label="Baseline spend"
                    localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                    value={initiativeInstanceStore.f_c[0].instance.baseline_spend ?? 0}
                    endAd={
                        <Tooltip
                            title="This is an estimated current spend in the scope, this does not impact savings tracking reports">
                            <Info fontSize="small" className="mx-1"/>
                        </Tooltip>
                    }
                    onChange={(event) =>
                        initiativeInstanceStore.f_c[0].baselineSpend = parseFloat(event.target.value) || 0
                    }/>
            </Grid>

            <Grid item xs={6}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="investment-details"
                                      id="investment-header">
                        Investment details
                    </AccordionSummary>
                    <AccordionDetails>
                        <SitCurrency
                            disabled={initiativeInstanceStore.disabled}
                            localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                            label="Required Investment"
                            value={initiativeInstanceStore.f_c[0].instance.investment ?? 0}
                            endAd={
                                <Tooltip
                                    title="Investment required to realize the savings, this is considered as a one-off spend and is deducted from saving tracking reports">
                                    <Info fontSize="small" className="mx-1"/>
                                </Tooltip>
                            }
                            onChange={(event) =>
                                initiativeInstanceStore.f_c[0].investment = parseFloat(event.target.value) || 0
                            }/>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item xs={6}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="severance-details"
                                      id="severance-header">
                        Severance details
                    </AccordionSummary>
                    <AccordionDetails>
                        <SitCurrency
                            disabled={initiativeInstanceStore.disabled}
                            localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                            label="Severance"
                            value={initiativeInstanceStore.f_c[0].instance.severance ?? 0}
                            endAd={
                                <Tooltip
                                    title="Penalties needed to be paid out to realize the savings, this is considered as a one-off spend and is deducted from saving tracking reports">
                                    <Info fontSize="small" className="mx-1"/>
                                </Tooltip>
                            }
                            onChange={(event) =>
                                initiativeInstanceStore.f_c[0].severance = parseFloat(event.target.value) || 0
                            }/>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item xs={6}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="cost-saving-details"
                                      id="cost-saving-header">
                        Cost saving details
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <SitCurrency
                                    disabled={initiativeInstanceStore.disabled}
                                    label="Total Cost Savings"
                                    localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                                    value={initiativeInstanceStore.f_c[0].instance.saving_amount ?? 0}
                                    onChange={(event) =>
                                        initiativeInstanceStore.f_c[0].savingAmount = parseFloat(event.target.value) || 0
                                    }
                                    endAd={<Tooltip title="Click to fill in calculations"><span>
                            <IconButton
                                disabled={initiativeInstanceStore.disabled}
                                onClick={() => initiativeInstanceStore.f_c[0].fillSavings(true)}>
                                <CalculateIcon/>
                            </IconButton></span>
                                    </Tooltip>}/>
                            </Grid>

                            <Grid item xs={6}>
                                <SitCurrency
                                    disabled={initiativeInstanceStore.disabled}
                                    label="Rolling Year Cost Saving"
                                    localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                                    value={initiativeInstanceStore.f_c[0].instance.normal_year_saving_amount ?? 0}
                                    endAd={
                                        <Tooltip
                                            title="The portion of savings falling in the current year, assuming a 12-month running logic">
                                            <Info fontSize="small" className="mx-1"/>
                                        </Tooltip>
                                    }
                                    onChange={(event) =>
                                        initiativeInstanceStore.f_c[0].normalYearSavingAmount = parseFloat(event.target.value) || 0
                                    }/>
                                <FormHelperText>12 Month Running</FormHelperText>
                            </Grid>

                            <Grid item xs={6}>
                                <SitCurrency
                                    disabled={initiativeInstanceStore.disabled}
                                    localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                                    label="Carryover Cost Saving"
                                    value={initiativeInstanceStore.f_c[0].instance.normal_carry_over_saving_amount ?? 0}
                                    endAd={
                                        <Tooltip
                                            title="The portion of savings carries over to the next year">
                                            <Info fontSize="small" className="mx-1"/>
                                        </Tooltip>
                                    }
                                    onChange={(event) =>
                                        initiativeInstanceStore.f_c[0].normalCarryOverSavingAmount = parseFloat(event.target.value) || 0
                                    }/>
                                <FormHelperText>12 Month Running</FormHelperText>
                            </Grid>

                            <Grid item xs={6}>
                                <SitCurrency
                                    disabled={initiativeInstanceStore.disabled}
                                    localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                                    label="Fiscal Year Cost Saving"
                                    value={initiativeInstanceStore.f_c[0].instance.fiscal_year_saving_amount ?? 0}
                                    endAd={
                                        <Tooltip
                                            title="The portion of savings falling in the current year considering a fiscal year logic of resetting budget on the fiscal year month">
                                            <Info fontSize="small" className="mx-1"/>
                                        </Tooltip>
                                    }
                                    onChange={(event) =>
                                        initiativeInstanceStore.f_c[0].fiscalYearSavingAmount = parseFloat(event.target.value) || 0
                                    }/>
                                <FormHelperText>Finance Method</FormHelperText>
                            </Grid>

                            <Grid item xs={6}>
                                <SitCurrency
                                    disabled={initiativeInstanceStore.disabled}
                                    localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                                    label="Carryover Cost Saving"
                                    value={initiativeInstanceStore.f_c[0].instance.fiscal_carry_over_saving_amount ?? 0}
                                    endAd={
                                        <Tooltip
                                            title="The remaining savings carries over to the next year">
                                            <Info fontSize="small" className="mx-1"/>
                                        </Tooltip>
                                    }
                                    onChange={(event) =>
                                        initiativeInstanceStore.f_c[0].fiscalCarryOverSavingAmount = parseFloat(event.target.value) || 0
                                    }/>
                                <FormHelperText>Finance Method</FormHelperText>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        disabled={initiativeInstanceStore.disabled}
                                        id="saving_percentage"
                                        variant="outlined"
                                        label="Fiscal Year Cost Saving Percentage"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <PercentIcon/>
                                                <Tooltip
                                                    title="Ratio of savings over the base line spend">
                                                    <Info fontSize="small" className="mx-1"/>
                                                </Tooltip>
                                            </InputAdornment>
                                        }}
                                        value={initiativeInstanceStore.f_c[0].instance.saving_percentage ?? 0}
                                        onChange={(event) =>
                                            initiativeInstanceStore.f_c[0].savingPercentage = parseFloat(event.target.value) || 0
                                        }/>
                                </FormControl>
                            </Grid>

                            {/*<Grid item xs={6}>*/}
                            {/*    <FormControl fullWidth>*/}
                            {/*        <TextField*/}
                            {/*            disabled={initiativeInstanceStore.disabled}*/}
                            {/*            id="carry_over_saving_percentage"*/}
                            {/*            variant="outlined"*/}
                            {/*            label="Carryover Cost Saving Percentage"*/}
                            {/*            InputProps={{*/}
                            {/*                endAdornment: <InputAdornment position="end">*/}
                            {/*                    <PercentIcon/>*/}
                            {/*                </InputAdornment>*/}
                            {/*            }}*/}
                            {/*            value={fc.instance.carry_over_saving_percentage ?? 0}*/}
                            {/*            onChange={(event) =>*/}
                            {/*                fc.carryOverSavingPercentage = parseFloat(event.target.value) || 0*/}
                            {/*            }/>*/}
                            {/*    </FormControl>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item xs={6}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="cost-saving-details"
                                      id="cost-avoidance-header">
                        Cost avoidance details
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <SitCurrency
                                    disabled={initiativeInstanceStore.disabled}
                                    localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                                    label="Total Cost Avoidance"
                                    value={initiativeInstanceStore.f_c[0].instance.avoidance_amount ?? 0}
                                    onChange={(event) =>
                                        initiativeInstanceStore.f_c[0].avoidanceAmount = parseFloat(event.target.value) || 0
                                    }
                                    endAd={<Tooltip title="Click to fill in calculations"><span>
                            <IconButton
                                disabled={initiativeInstanceStore.disabled}
                                onClick={() => initiativeInstanceStore.f_c[0].fillAvoidances(true)}>
                                <CalculateIcon/>
                            </IconButton></span>
                                    </Tooltip>}/>
                            </Grid>

                            <Grid item xs={6}>
                                <SitCurrency
                                    disabled={initiativeInstanceStore.disabled}
                                    localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                                    label="Rolling Year Cost Avoidance"
                                    value={initiativeInstanceStore.f_c[0].instance.normal_year_avoidance_amount ?? 0}
                                    endAd={
                                        <Tooltip
                                            title="The portion of avoidance falling in the current year, assuming a 12-month running logic">
                                            <Info fontSize="small" className="mx-1"/>
                                        </Tooltip>
                                    }
                                    onChange={(event) =>
                                        initiativeInstanceStore.f_c[0].normalYearAvoidanceAmount = parseFloat(event.target.value) || 0
                                    }/>
                                <FormHelperText>12 Month Running</FormHelperText>
                            </Grid>

                            <Grid item xs={6}>
                                <SitCurrency
                                    disabled={initiativeInstanceStore.disabled}
                                    localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                                    label="Carryover Cost Avoidance"
                                    value={initiativeInstanceStore.f_c[0].instance.normal_carry_over_avoidance_amount ?? 0}
                                    endAd={
                                        <Tooltip
                                            title="The portion of avoidance carries over to the next year">
                                            <Info fontSize="small" className="mx-1"/>
                                        </Tooltip>
                                    }
                                    onChange={(event) =>
                                        initiativeInstanceStore.f_c[0].normalCarryOverAvoidanceAmount = parseFloat(event.target.value) || 0
                                    }/>
                                <FormHelperText>12 Month Running</FormHelperText>
                            </Grid>

                            <Grid item xs={6}>
                                <SitCurrency
                                    disabled={initiativeInstanceStore.disabled}
                                    localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                                    label="Fiscal Year Cost Avoidance"
                                    value={initiativeInstanceStore.f_c[0].instance.fiscal_year_avoidance_amount ?? 0}
                                    endAd={
                                        <Tooltip
                                            title="The portion of cost avoidance falling in the current year considering a fiscal year logic of resetting budget on the fiscal year month">
                                            <Info fontSize="small" className="mx-1"/>
                                        </Tooltip>
                                    }
                                    onChange={(event) =>
                                        initiativeInstanceStore.f_c[0].fiscalYearAvoidanceAmount = parseFloat(event.target.value) || 0
                                    }/>
                                <FormHelperText>Finance Method</FormHelperText>
                            </Grid>

                            <Grid item xs={6}>
                                <SitCurrency
                                    disabled={initiativeInstanceStore.disabled}
                                    localCurrencyId={initiativeInstanceStore.f_c[0].instance.currency_id}
                                    label="Carryover Cost Avoidance"
                                    value={initiativeInstanceStore.f_c[0].instance.fiscal_carry_over_avoidance_amount ?? 0}
                                    endAd={
                                        <Tooltip
                                            title="The remaining avoidance carries over to the next year">
                                            <Info fontSize="small" className="mx-1"/>
                                        </Tooltip>
                                    }
                                    onChange={(event) =>
                                        initiativeInstanceStore.f_c[0].fiscalCarryOverAvoidanceAmount = parseFloat(event.target.value) || 0
                                    }/>
                                <FormHelperText>Finance Method</FormHelperText>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        disabled={initiativeInstanceStore.disabled}
                                        id="avoidance_percentage"
                                        variant="outlined"
                                        label="Fiscal Year Cost Avoidance Percentage"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <PercentIcon/>
                                                <Tooltip
                                                    title="Ratio of avoidance over the base line spend">
                                                    <Info fontSize="small" className="mx-1"/>
                                                </Tooltip>
                                            </InputAdornment>
                                        }}
                                        value={initiativeInstanceStore.f_c[0].instance.avoidance_percentage ?? 0}
                                        onChange={(event) =>
                                            initiativeInstanceStore.f_c[0].avoidancePercentage = parseFloat(event.target.value) || 0
                                        }/>
                                </FormControl>
                            </Grid>

                            {/*<Grid item xs={6}>*/}
                            {/*    <FormControl fullWidth>*/}
                            {/*        <TextField*/}
                            {/*            disabled={initiativeInstanceStore.disabled}*/}
                            {/*            id="carry_over_avoidance_percentage"*/}
                            {/*            variant="outlined"*/}
                            {/*            label="Carryover Cost Avoidance Percentage"*/}
                            {/*            InputProps={{*/}
                            {/*                endAdornment: <InputAdornment position="end">*/}
                            {/*                    <PercentIcon/>*/}
                            {/*                </InputAdornment>*/}
                            {/*            }}*/}
                            {/*            value={fc.instance.carry_over_avoidance_percentage ?? 0}*/}
                            {/*            onChange={(event) =>*/}
                            {/*                fc.carryOverAvoidancePercentage = parseFloat(event.target.value) || 0*/}
                            {/*            }/>*/}
                            {/*    </FormControl>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    })
;