import React, {useState} from "react";
import {Button, ButtonGroup, CircularProgress, FormControlLabel, Grid, Switch} from "@mui/material";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import './KoiKeyDataFacts.scss';
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {toCurrency} from "../../../components/currency-component/CurrencyClasses";
import Typography from "@mui/material/Typography";
import {Bag} from "../../../services/classes/Bag";
import {
    CollapsibleIndentTree,
    FilterSpecification,
    Options,
    TreeDataType
} from "../../../components/visualization/collapsible-indent-tree/CollapsibleIndentTree";
import {FilteredSankeyComponent} from "./FilteredSankeyComponent";
import {Subject} from "rxjs";
import {UNCATEGORIZED_LABEL} from "../../../constants";
import * as d3 from "d3";
import {MatKpiTreeData} from "../../../services/ApiTypes";
import {useLocation} from "react-router-dom";
import {generateHomePath} from "../../../routing/routing.utils";
import {CloudDownload, Done} from "@mui/icons-material";
import {analyticsExportEvent} from "../../../services/analytics/AnalyticEvents";

// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         acceptButton: {
//             margin: theme.spacing(2),
//         },
//         textRight: {
//             textAlign: 'right',
//         },
//     }),
// );

export const KoiKeyDataFacts: React.FC = observer(() => {
    const {rootAppStore, bagStore, authStore, materializedApi, p} = useStores();
    const location = useLocation();
    const bagId = bagStore.bagId;
    const bag = bagStore.bag as Bag;

    const [filter, setFilter] = React.useState<any | undefined>(0);
    const hiddenRoot = false;
    const [hiddenUncat, setHiddenUncat] = React.useState<boolean>(true);
    const [isDownloadingExcel, setIsDownloadingExcel] = useState(false);
    const [hasDownloadedExcel, setHasDownloadedExcel] = useState(false);
    const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);
    const [hasDownloadedCsv, setHasDownloadedCsv] = useState(false);

    const $filter = React.useMemo<FilterSpecification>(() => ({
        byLevel: new Subject<number>(),
    }), []);

    const options = React.useMemo<Partial<Options<MatKpiTreeData>> | undefined>(() => $filter ? {
        height: 5800,
        width: 720,
        $filter,
        columns: [
            {
                columnTitle: 'Total spend',
                getValue: (d, c) => {
                    return String(c.hiddenRoot && d.depth === 0 ? '' : toCurrency(p.currencySymbol, d.data.values.spend));
                },
                xOffset: 540,
            },
            {
                columnTitle: 'Suppliers',
                getValue: (d, c) =>
                    String(c.hiddenRoot && d.depth === 0 ? '' : (d.data.values.distinct_suppliers || '-')),
                xOffset: 610,
            },
            {
                columnTitle: 'Transactions',
                getValue: (d, c) =>
                    String(c.hiddenRoot && d.depth === 0 ? '' : (d.data.values.parts || '-')),
                xOffset: 700,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } : undefined, [$filter])

    // const clickContinue = () => {
    //     jobStore.setStep(2);
    //     // // Start enrichment
    //     // const oldJob = job;
    //     // loadingBar.start(true);
    //     // // Note: skipped enriching for now
    //     // JobService.startJobCategorization(oldJob.id).then(nextJob => {
    //     //     jobStore.setJob(nextJob);
    //     // }).finally(() => {
    //     //     loadingBar.setComplete();
    //     // })
    // };

    return <KoiFacade
        title="Explore Categories"
        // helpContent={<PurchasePriceVarianceHelp/>}
        intro={<></>}
        back={generateHomePath(location, {id: String(bagId)}, rootAppStore.app)} // Deprecated
        className="koi-kdf-page"
    >
        {/*<div className="page-item">*/}
        {/*    /!* Header *!/*/}
        {/*    <Grid container justifyContent="space-evenly">*/}
        {/*        /!*<Grid item xs={2}>*!/*/}
        {/*        /!*    /!*<Typography variant="subtitle1">Spend per category</Typography>*!/*!/*/}
        {/*        /!*</Grid>*!/*/}
        {/*        <Grid item xs={5}>*/}
        {/*            <Typography variant="h6" align="center">Sum of variance cost per category</Typography>*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs={4}/>*/}
        {/*    </Grid>*/}
        {/*</div>*/}

        {/*<Grid container justifyContent="space-evenly">*/}
        {/*    /!*<Grid item xs={2}/>*!/*/}
        {/*</Grid>*/}

        {/*<div className="page-item bar-chart-item">*/}
        {/*    <Grid container spacing={1} direction="row">*/}
        {/*        <Grid item xs={6} sm={2}>*/}
        {/*            /!*Disabled for RASA demo*!/*/}
        {/*            /!*<JobKpis/>*!/*/}
        {/*        </Grid>*/}
        {/*        /!*<Grid item xs={6} sm={4}>*!/*/}
        {/*        /!*    <div style={{height: '450px'}}>*!/*/}
        {/*        /!*        <PieView data={pieData} loading={!pieData}/>*!/*/}
        {/*        /!*    </div>*!/*/}
        {/*        /!*    <p className="text-center mb-0">The spend per category (of L1)</p>*!/*/}
        {/*        /!*    /!*{barData ? <>*!/*!/*/}
        {/*        /!*    /!*        <BarChart data={barData} height={110 + 22} labelMargin={22} valueLabelPxW={30}/>*!/*!/*/}
        {/*        /!*    /!*        <p className="text-center mb-0" style={{marginLeft: '12%'}}>*!/*!/*/}
        {/*        /!*    /!*            The number of records per category*!/*!/*/}
        {/*        /!*    /!*        </p>*!/*!/*/}
        {/*        /!*    /!*    </>*!/*!/*/}
        {/*        /!*    /!*    : <p>Loading</p>}*!/*!/*/}
        {/*        /!*</Grid>*!/*/}
        {/*        <Grid item xs={6} sm={5}>*/}
        {/*            <Grid className="key-value-container text-center font-bigger"*/}
        {/*                  container*/}
        {/*                  style={{justifyContent: "space-evenly"}}>*/}
        {/*                <Grid item className="font-bigger">*/}
        {/*                    <Typography variant="caption">*/}
        {/*                        Total spend*/}
        {/*                    </Typography>*/}
        {/*                    <Typography variant="h5" className="font-weight-bold key-value">*/}
        {/*                        {jobStats*/}
        {/*                            ? <CurrencyComponent v={jobStats.total_spend} nDigits={3}/>*/}
        {/*                            : <>&nbsp;</>}*/}
        {/*                    </Typography>*/}
        {/*                </Grid>*/}
        {/*                <Grid item className="font-bigger">*/}
        {/*                    <Typography variant="caption">*/}
        {/*                        Unclassified*/}
        {/*                    </Typography>*/}
        {/*                    <Typography variant="h5" className="font-weight-bold key-value">*/}
        {/*                        {jobStats*/}
        {/*                            ? <CurrencyComponent v={jobStats.unclassified_spend} nDigits={3}/>*/}
        {/*                            : <>&nbsp;</>}*/}
        {/*                    </Typography>*/}
        {/*                </Grid>*/}
        {/*            </Grid>*/}
        {/*            /!*<div style={{height: '250px'}}>*!/*/}
        {/*            /!*    <PieView data={pieData} loading={!pieData}/>*!/*/}
        {/*            /!*</div>*!/*/}
        {/*            /!*<p className="text-center mb-0">The spend per category (of L1)</p>*!/*/}
        {/*        </Grid>*/}
        {/*    </Grid>*/}
        {/*</div>*/}

        {p.p.enableSankey &&
            <div className="page-item sankey-item">
                <FilteredSankeyComponent/>
            </div>
        }

        {bagStore.kpi.taxonomyData && <>
            <section className="page-item taxonomy-tree-item">
                <header className="taxonomy-tree-header">
                    <Grid container>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <Typography variant="h3" component="h2">
                                Category overview
                            </Typography>
                        </Grid>
                        <Grid item xs={2} className="my-auto">
                            {p.p.allowReviewDownloadExcel &&
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    className="btn-with-circular-progress button-spacing"
                                    startIcon={isDownloadingExcel ? <CircularProgress size={14}/> : (hasDownloadedExcel ?
                                        <Done/> : <CloudDownload/>)}
                                    endIcon={isDownloadingExcel ? undefined : (hasDownloadedExcel ? undefined : undefined)}
                                    disabled={isDownloadingExcel}
                                    onClick={() => {
                                        setIsDownloadingExcel(true)
                                        analyticsExportEvent({
                                            export_type: 'categorization',
                                            object_key: String(bagId),
                                            user: authStore.email
                                        })
                                        const filename = (bagStore.bag?.name ?? bagId).toString() + '.xlsx';
                                        materializedApi.downloadReviewExcel(bagId, filename)
                                            .then(resp => {
                                                const url = URL.createObjectURL(new Blob([resp.data]));
                                                const link = document.createElement('a');
                                                link.href = url;
                                                link.setAttribute('download', filename);
                                                document.body.appendChild(link);
                                                link.click();
                                                link.parentNode?.removeChild(link);
                                                setHasDownloadedExcel(true)
                                            })
                                            .finally(() => {
                                                setIsDownloadingExcel(false)
                                            })
                                    }}>
                                    {isDownloadingExcel ? 'Preparing data' : 'Download Excel file'}
                                </Button>
                            }
                        </Grid>
                        <Grid item xs={2} className="my-auto">
                            {p.p.allowReviewDownloadCsv &&
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    className="btn-with-circular-progress button-spacing"
                                    startIcon={isDownloadingCsv ? <CircularProgress size={14}/> : (hasDownloadedCsv ?
                                        <Done/> : <CloudDownload/>)}
                                    endIcon={isDownloadingCsv ? undefined : (hasDownloadedCsv ? undefined : undefined)}
                                    disabled={isDownloadingCsv}
                                    onClick={() => {
                                        setIsDownloadingCsv(true)
                                        analyticsExportEvent({
                                            export_type: 'categorization',
                                            object_key: String(bagId),
                                            user: authStore.email
                                        })
                                        const filename = (bagStore.bag?.name ?? bagId).toString() + '.zip';
                                        materializedApi.downloadReviewCsv(bagId, filename)
                                            .then(resp => {
                                                const url = URL.createObjectURL(new Blob([resp.data]));
                                                const link = document.createElement('a');
                                                link.href = url;
                                                link.setAttribute('download', filename);
                                                document.body.appendChild(link);
                                                link.click();
                                                link.parentNode?.removeChild(link);
                                                setHasDownloadedCsv(true)
                                            })
                                            .finally(() => {
                                                setIsDownloadingCsv(false)
                                            })
                                    }}>
                                    {isDownloadingCsv ? 'Preparing data' : 'Download CSV file'}
                                </Button>
                            }
                        </Grid>
                    </Grid>
                    <div className="buttons">
                        <ButtonGroup className="mx-4">
                            {[...Array(bag.taxonomy_size)].map((_, i) =>
                                <Button
                                    key={i}
                                    color="primary"
                                    onClick={() => {
                                        // bugfix when pressing L3->L1->L2
                                        ($filter.byLevel as Subject<number>).next(i);
                                        ($filter.byLevel as Subject<number>).next(i);
                                    }}
                                    variant="outlined">
                                    L{String(i + 1)}
                                </Button>
                            )}
                        </ButtonGroup>
                        <ButtonGroup className="mx-4">
                            <Button onClick={() => setFilter(0)} variant={filter === 0 ? 'contained' : 'outlined'}
                                    color="primary">
                                No filter
                            </Button>
                            <Button onClick={() => setFilter(1)} variant={filter === 1 ? 'contained' : 'outlined'}
                                    color="primary">
                                &gt;= {toCurrency(p.currencySymbol, 1_000)}
                            </Button>
                            <Button onClick={() => setFilter(2)} variant={filter === 2 ? 'contained' : 'outlined'}
                                    color="primary">
                                &gt;= {toCurrency(p.currencySymbol, 1_000_000)}
                            </Button>
                            <Button onClick={() => setFilter(3)} variant={filter === 3 ? 'contained' : 'outlined'}
                                    color="primary">
                                &gt;= 5 sup
                            </Button>
                            <Button onClick={() => setFilter(4)} variant={filter === 4 ? 'contained' : 'outlined'}
                                    color="primary">
                                &gt;= 100 transactions
                            </Button>
                        </ButtonGroup>
                        {/*<Button onClick={() => setHiddenRoot(!hiddenRoot)} variant="outlined"*/}
                        {/*        color="primary" className="ml-4">*/}
                        {/*    {hiddenRoot ? 'Show total' : 'Hide total'}*/}
                        {/*</Button>*/}

                        <FormControlLabel
                            className="mx-4"
                            control={<Switch size="small"
                                             checked={!hiddenUncat}
                                             onChange={() => setHiddenUncat(!hiddenUncat)}/>}
                            label={UNCATEGORIZED_LABEL}
                        />
                        {/*<Button onClick={() => setHiddenUncat(!hiddenUncat)}*/}
                        {/*        variant={hiddenUncat ? 'contained' : 'outlined'}*/}
                        {/*        color="primary" className="ml-4">*/}
                        {/*    {hiddenUncat ? 'Hide uncategorized' : 'Show uncategorized'}*/}
                        {/*</Button>*/}
                    </div>
                </header>
                <Grid container justifyContent="center">
                    <Grid item xs={7}>
                        <CollapsibleIndentTree
                            data={bagStore.kpi.taxonomyData}
                            options={options}
                            filter={filter}
                            hiddenRoot={hiddenRoot}
                            hiddenUncat={hiddenUncat}
                        />
                    </Grid>
                </Grid>
            </section>
        </>}
    </KoiFacade>
});

type Filter = undefined | ((d: d3.HierarchyNode<TreeDataType<MatKpiTreeData>>) => boolean);
export const CDT_FILTERS: Filter[] = [
    undefined,
    d => d?.data?.values.spend >= 1_000,
    d => d?.data?.values.spend >= 1_000_000,
    d => d?.data?.values.distinct_suppliers >= 5,
    d => d?.data?.values.distinct_suppliers >= 100,
]
