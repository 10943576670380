import './MultiDropdownMenu.scss'
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {generatePath, useNavigate, useParams} from "react-router-dom";
import {routes} from "../../../routing/routes";
import {Badge, Chip, Divider, Grow, ListItem, Menu, MenuItem, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {AccountCircleOutlined} from "@mui/icons-material";
import {useAuth0} from '@auth0/auth0-react';
import {environment} from "../../../env";
import {MithraStaffDebugMenu} from "../../default-menus/debug-menu/MithraStaffDebugMenu";

const menuId = 'multi-dropdown-menu';

export const MultiDropdownMenu: React.FC = observer(() => {
    const {p, authStore, approvalStore} = useStores();
    const {logout} = useAuth0();
    const navigate = useNavigate();
    const params = useParams();
    const hasId1 = params.id !== undefined;

    const [menuElement, setMenuElement] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(menuElement);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuElement(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuElement(null);
    };

    const handleLogout = () => {
        authStore.auth0Logout(logout);
        navigate(routes.login);
    };

    const renderMenu = (
        <Menu
            anchorEl={menuElement}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}
            TransitionComponent={Grow}
        >
            <ListItem title={(authStore.userId ? `[${authStore.userId}] ` : '') + authStore.email}>
                {authStore.displayUser}
            </ListItem>

            {authStore.viewOnly && <MenuItem disabled>
                <Typography variant="inherit">This account is view-only</Typography>
            </MenuItem>}

            <MenuItem disabled>
                <Typography variant="inherit">
                    Account type:
                    {authStore.customerRole}
                    {authStore.isMithraStaff ? ' (Staff)' : ''}
                </Typography>
            </MenuItem>

            {(environment.isTest || authStore.isMithraStaff) && <MithraStaffDebugMenu/>}

            <Divider/>

            {!p.p.hideLinksUserDropDownMenu && <MenuItem onClick={() => {
                handleMenuClose()
                navigate(hasId1 ? generatePath(routes.single_analytics_dashboard, params) : routes.single_data_home);
            }}>
                Analytics
            </MenuItem>}
            {!p.p.hideLinksUserDropDownMenu && <MenuItem onClick={() => {
                handleMenuClose()
                navigate(hasId1 ? generatePath(routes.synergy_v3_dashboard, params) : routes.synergy_v3);
            }}>
                Synergy
            </MenuItem>}
            {authStore.isCustomerSuper &&
                <MenuItem onClick={() => {
                    handleMenuClose()
                    navigate(routes.approval);
                }}>
                    Awaiting approvals
                    {approvalStore.pendingRequests !== undefined &&
                        <Chip size="small" label={approvalStore.pendingRequests}
                              className="color-error"/>
                    }
                    (Leaves app)
                </MenuItem>
            }
            {!p.p.hideLinksUserDropDownMenu && <MenuItem onClick={() => {
                handleMenuClose()
                if (hasId1) {
                    navigate(generatePath(routes.single_looker_preview, params));
                }
            }}>
                Advanced Dashboard
            </MenuItem>}

            <Divider/>

            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
    );

    return <>
        <IconButton
            edge="end"
            size="medium"
            aria-label="account"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit">
            {
                authStore.isCustomerSuper && approvalStore.pendingRequests !== undefined
                    ? <Badge
                        badgeContent={approvalStore.pendingRequests}
                        color="error">
                        <AccountCircleOutlined/>
                    </Badge>
                    : <AccountCircleOutlined/>
            }
        </IconButton>
        {renderMenu}
    </>
})
