import {SimpleEnvWrapper} from "../jobs/wrapper/SimpleEnvWrapper";
import {SpendConcentration} from "../jobs/koi/spend-concentration/SpendConcentration";
import {KOIPurchasePriceVariance} from "../jobs/koi/purchase-price-variance/KOIPurchasePriceVariance";
import {MainComponent} from "../components/main/MainComponent";
import {KoiKeyDataFacts} from "../jobs/koi/key-data-facts/KoiKeyDataFacts";
import {KoiSupplierHierarchy} from "../jobs/koi/supplier-hierarchy/KoiSupplierHierarchy";
import {ManageDataPage} from "../pages/manage-data/ManageDataPage";
import {KOICashFlowOptimization} from "../jobs/koi/koi-cash-flow-optimization/KOICashFlowOptimization";
import {KOISupplierSegmentation} from "../jobs/koi/koi-supplier-segmentation/KOISupplierSegmentation";
import {SynergySelectionPage} from "../pages/synergy_v1/selection/SynergySelectionPage";
import {SynergyDashboardPage} from "../pages/synergy_v1/dashboard/SynergyDashboardPage";
import {SynergyOverviewPage} from "../pages/synergy_v1/SynergyOverviewPage";
import {routes} from './routes';
import {SynergySuppliersPage} from "../pages/synergy_v1/suppliers/SynergySuppliersPage";
import {SynergyCategoriesPage} from "../pages/synergy_v1/categories/SynergyCategoriesPage";
import {DoubleEnvWrapper} from "../jobs/wrapper/DoubleEnvWrapper";
import {OpportunitiesTrello} from "../pages/opportunities-trello/OpportunitiesTrello";
import {DashBoard} from '../pages/dashboard/Dashboard';
import {TaxonomyEditorPage} from "../pages/taxonomy-editor/TaxonomyEditorPage";
import {ApprovalListPage} from "../pages/approval/ApprovalListPage";
import {ApprovalCategorizationPage} from "../pages/approval/categorization/ApprovalCategorizationPage";
import {ApprovalTaxonomyPage} from "../pages/approval/taxonomy/ApprovalTaxonomyPage";
import {TaxonomyMapperPage} from "../pages/taxonomy-mapper/TaxonomyMapperPage";
import {TaxonomyMapperReviewPage} from "../pages/taxonomy-mapper-review/TaxonomyMapperReviewPage";
import {TaxonomyEditorViewOnlyPage} from "../pages/taxonomy-editor/TaxonomyEditorViewOnlyPage";
import {ReportingPage} from "../pages/reporting/ReportingPage";
import {SingleCleaningDashboard} from "../pages/multi-dashboard/SingleCleaningDashboard";
import {SingleAnalyticsDashboard} from "../pages/multi-dashboard/SingleAnalyticsDashboard";
import {SynergyAnalyticsDashboard} from "../pages/multi-dashboard/SynergyAnalyticsDashboard";
import {SynergyMergeDashboard} from "../pages/multi-dashboard/SynergyMergeDashboard";
import {MultiEnvWrapper} from "../jobs/wrapper/MultiEnvWrapper";
import {ManageMasterDataPage} from "../pages/multi-dashboard/data/ManageMasterDataPage";
import {SupplierNormalizationPage} from "../pages/supplier-normalization/SupplierNormalizationPage";
import {DatasetOverviewPage} from '../pages/data-management/DatasetOverviewPage';
import {DataUploadPage} from '../pages/data-management/upload/DataUploadPage';
import {Merge1EnvWrapper} from "../jobs/wrapper/Merge1EnvWrapper";
import {Merge1Dashboard} from "../pages/multi-dashboard/nestle-dashboard/Merge1Dashboard";
import {LookerPreviewPage} from "../pages/looker-preview/LookerPreviewPage";
import {DataIngestionWrapper} from "../jobs/wrapper/DataIngestionWrapper";
import {DataMappingPage} from "../pages/data-management/DataMappingPage";
import {environment} from "../env";
import {CleanupDashboard} from "../pages/cleanup-dashboard/CleanupDashboard";
import {MergeXDashboard} from "../pages/multi-dashboard/merge-x-dashboard/MergeXDashboard";
import {MergeXEnvWrapper} from '../jobs/wrapper/MergeXEnvWrapper';
import {TaxonomySuggestorPage} from "../pages/taxonomy-suggestor/TaxonomySuggestorPage";
import {C} from '../configurations/active-configuration';
import {Merge2Dashboard} from "../pages/multi-dashboard/lg-dashboard/Merge2Dashboard";
import {Merge2EnvWrapper} from "../jobs/wrapper/Merge2EnvWrapper";
import {MinimalDashboard} from "../pages/dashboard/MinimalDashboard";
import {AdvancedDashboard} from "../pages/advanced/AdvancedDashboard";
import {CommonSuppliersPage} from "../pages/common-suppliers/CommonSuppliersPage";
import {CategorizationPage, CategorizationPageProps} from "../pages/categorization/CategorizationPage";
import {TaxonomyHealthCheckPage} from "../pages/taxonomy-health-checker/TaxonomyHealthCheckPage";
import {
    SalesDemoDashboardCleanup
} from "../pages/multi-dashboard/sales-demo-dashboard/cleanup/SalesDemoDashboardCleanup";
import {
    SalesDemoDashboardAnalytics
} from "../pages/multi-dashboard/sales-demo-dashboard/cleanup/SalesDemoDashboardAnalytics";
import {
    SalesDemoSynergyAnalyticsDashboard
} from "../pages/multi-dashboard/sales-demo-dashboard/synergy/SalesDemoSynergyAnalyticsDashboard";
import {
    SalesDemoSynergyMergeDashboard
} from "../pages/multi-dashboard/sales-demo-dashboard/synergy/SalesDemoSynergyMergeDashboard";
import {CategorizationStatisticsPage} from "../pages/categorization/CategorizationStatisticsPage";
import {BusinessCaseGeneratorPage} from "../pages/business-case-generator/BusinessCaseGeneratorPage";
import {BusinessCaseReportPage} from "../pages/business-case-generator/BusinessCaseReportPage";
import {GoldenRecordPage} from "../pages/golden-record/GoldenRecordPage";
import {TaxonomySuggestionDemoPage} from "../pages/taxonomy-suggestion-demo/TaxonomySuggestionDemoPage";
import {TaxonomySuggesterPageV2} from '../pages/taxonomy-suggester-v2/TaxonomySuggesterPageV2';
import {ContractInsightsPage} from "../pages/contract-insights/pages/ContractInsightsPage";
import {ContractAnalyticsPage} from "../pages/contract-insights/pages/ContractAnalyticsPage";
import {ContractOpportunities} from "../pages/contract-insights/pages/ContractOpportunities";
import {TaxonomyUploadPage} from "../pages/taxonomy-ingestion/TaxonomyUploadPage";
import {TaxonomyOverviewPage} from "../pages/taxonomy-ingestion/TaxonomyOverviewPage";
import {TaxonomyMappingPage} from "../pages/taxonomy-ingestion/TaxonomyMappingPage";
import {TaxonomyIngestionWrapper} from "../jobs/wrapper/TaxonomyIngestionWrapper";
import {KOIOpportunity} from "../pages/opportunity/OpportunityPage";
import {
    PpvOpportunityDetailPage
} from "../pages/opportunity/components/ppv-opportunity-detail/PpvOpportunityDetailPage";
import {SmallPpvComponentDemo} from "../test/echart-react-playground/echart-ppv-demo1";
import {EchartReactPlayground} from "../test/echart-react-playground/echart-react-playground";
import {MergeXCleanupDashboard} from "../pages/multi-dashboard/merge-x-cleanup-dashboard/MergeXCleanupDashboard";
import {MergeXCEnvWrapper} from "../jobs/wrapper/MergeXCEnvWrapper";
import {RouteOptions, RouteSpecification} from "./routeClasses";
import {RouteWrapperProps} from "../jobs/wrapper/RouteWrapperProps";

const r = routes; // shortcut
type WO = RouteOptions<RouteWrapperProps, never>; //shortcut

/**
 * No data wrapper
 */
const noD: WO = {
    wrapper: SimpleEnvWrapper, wProps: {state: {fluid: true, dashEnv: 'simple', dataType: 'no_data'}}
};

const noDA: WO = {
    wrapper: SimpleEnvWrapper, wProps: {
        state: {
            fluid: true,
            dashEnv: 'simple',
            dataType: 'no_data',
            dashboardEnvironment: 'approval'
        }
    }
} // No data wrapper
const sim: WO = {wrapper: SimpleEnvWrapper, wProps: {state: {fluid: true, dashEnv: 'simple'}}}
const sif: WO = {wrapper: SimpleEnvWrapper, wProps: {state: {fluid: false, dashEnv: 'simple'}}}
const duo: WO = {wrapper: DoubleEnvWrapper, wProps: {state: {fluid: true, dashEnv: 'dual'}}}
const ms1: WO = {
    wrapper: MultiEnvWrapper,
    wProps: {state: {fluid: true, dashEnv: 'multi', dashType: 'single', dataType: 'default'}}
}
const ms1f: WO = {
    wrapper: MultiEnvWrapper,
    wProps: {state: {fluid: false, dashEnv: 'multi', dashType: 'single', dataType: 'default'}}
}
const msy: WO = {
    wrapper: MultiEnvWrapper,
    wProps: {state: {fluid: true, dashEnv: 'multi', dashType: 'synergy', dataType: 'default'}}
}
const dwmNoData: WO = {
    wrapper: DataIngestionWrapper,
    wProps: {state: {fluid: true, dataType: 'no_data', dashboardEnvironment: 'data-ingestion'}}
}
const dwmData: WO = {
    wrapper: DataIngestionWrapper,
    wProps: {state: {fluid: true, dashboardEnvironment: 'data-ingestion'}},
}
const dwmNoTaxonomy: WO = {
    wrapper: TaxonomyIngestionWrapper,
    wProps: {state: {fluid: true, dataType: 'no_data', dashboardEnvironment: 'data-ingestion'}},
}
const dwmTaxonomy: WO = {
    wrapper: TaxonomyIngestionWrapper,
    wProps: {state: {fluid: true, dashboardEnvironment: 'data-ingestion'}},
}

// Sales Demo
const deC: WO = {wrapper: SimpleEnvWrapper, wProps: {state: {fluid: true, dashboardEnvironment: 'cleanup'}}}
const deA: WO = {wrapper: SimpleEnvWrapper, wProps: {state: {fluid: true, dashboardEnvironment: 'analytics'}}}
const deAf: WO = {wrapper: SimpleEnvWrapper, wProps: {state: {fluid: false, dashboardEnvironment: 'analytics'}}}
const deS: WO = {wrapper: SimpleEnvWrapper, wProps: {state: {fluid: true, dashboardEnvironment: 'synergy'}}}
const deM: WO = {wrapper: SimpleEnvWrapper, wProps: {state: {fluid: true, dashboardEnvironment: 'merge'}}}

const m1w: WO = {wrapper: Merge1EnvWrapper, wProps: {state: {fluid: true}}}
// LG
const m2C: WO = {wrapper: Merge2EnvWrapper, wProps: {state: {fluid: true, dashboardEnvironment: 'cleanup'}}}
const m2A: WO = {wrapper: Merge2EnvWrapper, wProps: {state: {fluid: true, dashboardEnvironment: 'analytics'}}}
const m2M: WO = {wrapper: Merge2EnvWrapper, wProps: {state: {fluid: true, dashboardEnvironment: 'merge'}}} // Note: SYNERGY = MERGE
// SHV
const mxC: WO = {wrapper: MergeXEnvWrapper, wProps: {state: {fluid: true, dashboardEnvironment: 'cleanup'}}}
const mxA: WO = {wrapper: MergeXEnvWrapper, wProps: {state: {fluid: true, dashboardEnvironment: 'analytics'}}}
const mxAf: WO = {wrapper: MergeXEnvWrapper, wProps: {state: {fluid: false, dashboardEnvironment: 'analytics'}}}
const mxM: WO = {wrapper: MergeXEnvWrapper, wProps: {state: {fluid: true, dashboardEnvironment: 'merge'}}} // Note: SYNERGY = MERGE
const mxMf: WO = {wrapper: MergeXEnvWrapper, wProps: {state: {fluid: false, dashboardEnvironment: 'merge'}}}
// Merge C Cleanup
const mcS: WO = {wrapper: MergeXCEnvWrapper, wProps: {state: {fluid: true, dashboardEnvironment: 'cleanup'}}} // single
const mcC: WO = {wrapper: MergeXCEnvWrapper, wProps: {state: {fluid: true, dashboardEnvironment: 'synergy'}}} // combined

// For SHV multi subsidiary environment
const mxM1N: WO = {
    wrapper: MergeXEnvWrapper,
    wProps: {state: {fluid: true, dashboardEnvironment: 'merge', dataType: 'sub1_new'}}
}
const mxM1O: WO = {
    wrapper: MergeXEnvWrapper,
    wProps: {state: {fluid: true, dashboardEnvironment: 'merge', dataType: 'sub1_old'}}
}
const mxM2N: WO = {
    wrapper: MergeXEnvWrapper,
    wProps: {state: {fluid: true, dashboardEnvironment: 'merge', dataType: 'sub2_new'}}
}
const mxM2O: WO = {
    wrapper: MergeXEnvWrapper,
    wProps: {state: {fluid: true, dashboardEnvironment: 'merge', dataType: 'sub2_old'}}
}
const mxM3N: WO = {
    wrapper: MergeXEnvWrapper,
    wProps: {state: {fluid: true, dashboardEnvironment: 'merge', dataType: 'sub3_new'}}
}
const mxM3O: WO = {
    wrapper: MergeXEnvWrapper,
    wProps: {state: {fluid: true, dashboardEnvironment: 'merge', dataType: 'sub3_old'}}
}

// TODO: These will go to cleanup
const mMNO: WO = {
    wrapper: MergeXEnvWrapper,
    wProps: {state: {fluid: true, dashboardEnvironment: 'merge', dataType: 'old_new'}}
}
const mM1: WO = {wrapper: MergeXEnvWrapper, wProps: {state: {fluid: true, dashboardEnvironment: 'merge'}}}


const noWrapper = {wrapper: MainComponent, wProps: {}} as RouteOptions<unknown, {}> as WO;

/**
 * TODO: test the user flow on the CategorizationPage routes.
 *  With CAT-1214 we updated all CategorizationV1Page and AiCategorizationPage to CategorizationPage
 *  We would still need to test moved routes for consistency in the navigation flow
 */

// region wrappedRouting
const wrappedRouting: RouteSpecification<RouteWrapperProps>[] = ([] as RouteSpecification<RouteWrapperProps>[]).concat([
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Default fallback Dashboard
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    [sim, r.job_dashboard, DashBoard],
    [ms1, r.single_cleansing_dashboard, SingleCleaningDashboard],
    [ms1, r.single_cleansing_sup_nor, SupplierNormalizationPage],
    [ms1, r.single_cleansing_cat_welcome, CategorizationPage], // TODO: test
    [ms1, r.single_cleansing_cat_busy, CategorizationPage], // TODO: test
    [ms1, r.single_cleansing_cat_review, CategorizationPage], // TODO: test
    [ms1, r.single_cleansing_cat_result, CategorizationPage], // TODO: test
    [ms1, r.single_cleansing_taxonomy_builder, TaxonomyEditorPage],
    [ms1, r.single_cleansing_taxonomy_builder_id, TaxonomyEditorPage],
    [ms1, r.single_data_manage, ManageDataPage],
    [noD, r.single_data_home2, ManageDataPage],
    [noD, r.single_data_home, ManageDataPage],
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Shared routes
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    [sif, r.job_advanced_dashboard, AdvancedDashboard],
    [sif, r.job_business_case_report_id, BusinessCaseReportPage],
    [sif, r.job_business_case_report, BusinessCaseGeneratorPage],
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Multi Dashboard
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // single_cleansing
    // N/A
    // single_analytics_dashboard
    [ms1, r.single_analytics_dashboard, SingleAnalyticsDashboard],
    [ms1, r.single_analytics_koi_ss, KOISupplierSegmentation],
    [ms1, r.single_analytics_koi_kdf, KoiKeyDataFacts],
    [ms1, r.single_analytics_koi_ppv, KOIPurchasePriceVariance],
    [ms1, r.single_analytics_koi_sc, SpendConcentration],
    [ms1f, r.single_analytics_adv_dashboard, AdvancedDashboard],
    // KOICashFlowOptimization
    // KoiSupplierHierarchy

    // Looker Preview Page (Deprecated)
    [ms1, r.single_looker_preview, LookerPreviewPage],

    [noD, r.sit_looker_preview, LookerPreviewPage, {
        componentProps: {
            className: 'narrow',
            lookerUrl: 'https://lookerstudio.google.com/embed/reporting/b58fdda0-3e7e-4f50-a071-19fcbf56e341/page/mKP8D'
        }
    }],

    // single dashboard rest
    [msy, r.single_data_opportunities, OpportunitiesTrello],
    [msy, r.single_data_opportunities_id, OpportunitiesTrello],
    [msy, r.single_data_report, ReportingPage],
    // synergy_v3_dashboard
    [msy, r.synergy_v3_suppliers, SynergySuppliersPage],
    [msy, r.synergy_v3_categories, SynergyCategoriesPage],
    [msy, r.synergy_v3_spend, SynergyDashboardPage],
    [msy, r.synergy_v3_data, ManageMasterDataPage],
    [msy, r.synergy_v3_dashboard, SynergyAnalyticsDashboard],
    // synergy_merge_dashboard
    [msy, r.synergy_merge_review, CategorizationPage], // TODO: test
    [msy, r.synergy_merge_review_result, CategorizationPage], // TODO: test
    [msy, r.synergy_merge_taxonomy_viewer, TaxonomyEditorViewOnlyPage],
    [msy, r.synergy_merge_taxonomy_viewer_id, TaxonomyEditorViewOnlyPage],
    [msy, r.synergy_merge_taxonomy_builder, TaxonomyEditorPage],
    [msy, r.synergy_merge_taxonomy_builder_id, TaxonomyEditorPage],
    // synergy_v3 rest
    [msy, r.synergy_v3_opportunities_id, OpportunitiesTrello],
    [msy, r.synergy_v3_opportunities, OpportunitiesTrello],
    [msy, r.synergy_v3_report, ReportingPage],
    [msy, r.synergy_merge_dashboard, SynergyMergeDashboard],
    // Must be at the end
    [msy, r.synergy_v3, ManageDataPage],
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // App logic
    [sim, r.job_koi_sc, SpendConcentration],
    [sim, r.job_koi_cfo, KOICashFlowOptimization],
    [sim, r.job_koi_ss, KOISupplierSegmentation],
    [sim, r.job_koi_ppv, KOIPurchasePriceVariance],
    [sim, r.job_koi_kdf, KoiKeyDataFacts],
    [sim, r.job_koi_sh, KoiSupplierHierarchy],
    [sim, r.job_supplier_normalization, SupplierNormalizationPage],

    // New user flow
    [sim, r.job_manage, ManageDataPage],
    [sim, r.ppv_opportunities_detail, PpvOpportunityDetailPage],
    [sim, r.koi_opportunities, KOIOpportunity],
    [sim, r.demo_opportunities, OpportunitiesTrello],
    [sim, r.job_report, ReportingPage],

    [sim, r.job_ai_cat_welcome, CategorizationPage], // TODO: test
    [sim, r.job_ai_cat_busy, CategorizationPage], // TODO: test
    [sim, r.job_ai_cat_review, CategorizationPage], // TODO: test
    [sim, r.job_ai_cat_result, CategorizationPage], // TODO: test


    [duo, r.job_synergy_v1_suppliers, SynergySuppliersPage],
    [duo, r.job_synergy_v1_categories, SynergyCategoriesPage],
    [duo, r.job_synergy_v1_dashboard, SynergyDashboardPage],
    [duo, r.job_synergy_v1_opportunities, OpportunitiesTrello],

    [sim, r.job_taxonomy_suggestor, TaxonomySuggestorPage],
    [sim, r.job_taxonomy_builder_id, TaxonomyEditorPage],
    [sim, r.job_taxonomy_builder, TaxonomyEditorPage],

    [duo, r.synergy_v2_dashboard, SynergyMergeDashboard],
    [duo, r.synergy_v2_review, CategorizationPage], // TODO: test
    [duo, r.synergy_v2_review_result, CategorizationPage], // TODO: test
    [sim, r.synergy_v2_taxonomy_viewer, TaxonomyEditorViewOnlyPage],
    [sim, r.synergy_v2_taxonomy_viewer_id, TaxonomyEditorViewOnlyPage],
    [sim, r.synergy_v2_taxonomy_builder_id, TaxonomyEditorPage],
    [duo, r.synergy_v2_opportunities, OpportunitiesTrello],
    [noD, r.synergy_v2_data, ManageDataPage],
    [noD, r.synergy_v2, ManageDataPage],

    [noDA, r.approval_cat_detail, ApprovalCategorizationPage],
    [noDA, r.approval_tax_detail, ApprovalTaxonomyPage],
    [noDA, r.approval, ApprovalListPage],

    // Catch for incomplete synergy views (must be at the end)
    [noD, r.job_synergy_v1_1, SynergySelectionPage],
    [duo, r.job_synergy_v1_2, SynergySelectionPage],
    [noD, r.job_synergy_v1_0, SynergySelectionPage],

    [sim, r.taxonomy_mapper_review, TaxonomyMapperReviewPage],
    [sim, r.taxonomy_mapper, TaxonomyMapperPage],

    // Looker Preview Page
    [noWrapper, r.looker_preview_id, LookerPreviewPage],
    [noWrapper, r.looker_preview, LookerPreviewPage],

    [dwmNoData, r.data_ingestion, DatasetOverviewPage],
    [dwmData, r.data_upload_mapping, DataMappingPage],
    [dwmNoData, r.data_upload, DataUploadPage],

    [dwmNoTaxonomy, r.taxonomy_ingestion, TaxonomyOverviewPage],
    [dwmTaxonomy, r.taxonomy_upload_mapping, TaxonomyMappingPage],
    [dwmNoTaxonomy, r.taxonomy_upload, TaxonomyUploadPage],

    [noD, r.test_small_ppv_component, SmallPpvComponentDemo],
    [noD, r.test_react_integration, EchartReactPlayground],
]).concat(environment.package === 'merge_1' ? [
    // Homepage
    [m1w, r.home, Merge1Dashboard],
] : [
    [noD, r.home, ManageDataPage],
]).concat(environment.package === 'merge_1' ? [
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Multi Dashboard
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    [m1w, r.merge_1_supplier_normalization_master, SupplierNormalizationPage],
    [m1w, r.merge_1_client_categorization_review, CategorizationPage],
    [m1w, r.merge_1_supplier_normalization, SupplierNormalizationPage],
    [m1w, r.merge_1_common_suppliers, CommonSuppliersPage],
    [m1w, r.merge_1_client_taxonomy_builder_id, TaxonomyEditorPage],
    [m1w, r.merge_1_client_taxonomy_builder, TaxonomyEditorPage],
    [m1w, r.merge_1_common_categories_review, TaxonomyMapperReviewPage],
    [m1w, r.merge_1_common_categories, TaxonomyMapperPage],
] : []).concat(environment.package === 'cleanup' ? [
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Cleanup dashboard
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    [sim, r.cleanup_dashboard, CleanupDashboard],
    [sim, r.supplier_normalization, SupplierNormalizationPage],
    [sim, r.categorization_statistics, CategorizationStatisticsPage],
    [sim, r.categorization_review, CategorizationPage], // TODO: test
    [sim, r.taxonomy_builder_id, TaxonomyEditorPage],
    [sim, r.taxonomy_builder, TaxonomyEditorPage],
    [sim, r.koi_spend_concentration, SpendConcentration],
    [sim, r.taxonomy_suggestor, TaxonomySuggestorPage],
    [sim, r.koi_ppv, KOIPurchasePriceVariance],

    [sim, r.cleanup_contract_insights, ContractInsightsPage],


    // For testing:
    [sim, r.job_old_dashboard, DashBoard],

    [sim, r.data_manage, ManageDataPage],
    [noD, r.data_home, ManageDataPage],
] : []).concat(environment.package === 'merge_2' ? ([
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Merge 2 Dashboard (Used for Liberty Global)
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    [mM1, r.merge_2_master_dashboard, Merge2Dashboard],

    // By default, go to the Cleanup dashboard
    [m2C, r.merge_2_dashboard, Merge2Dashboard],

    [m2M, r.merge_2_merge_taxonomy_mapper_baseline, TaxonomyMapperPage],
    [m2M, r.merge_2_merge_taxonomy_mapper_target, TaxonomyMapperPage],
    [m2M, r.merge_2_merge_categorization, CategorizationPage], // TODO: test
    [m2M, r.merge_2_merge_common_categories, TaxonomyMapperPage],
    [m2M, r.merge_2_merge_spend, SynergyDashboardPage, {
        componentProps: {
            noLink: true,
        },
    }],
    [m2M, r.merge_2_merge_dashboard, Merge2Dashboard],
    [m2M, r.merge_2_merge_synergy_overview, SynergyOverviewPage],
    [m2M, r.merge_2_merge_opportunity, AdvancedDashboard],

    [m2C, r.merge_2_cleanup_taxonomy_viewer_id, TaxonomyEditorViewOnlyPage], // Also in extended
    [m2C, r.merge_2_cleanup_taxonomy_viewer, TaxonomyEditorViewOnlyPage],  // Also in extended
    [m2C, r.merge_2_cleanup_supplier_normalization, SupplierNormalizationPage],
    [m2C, r.merge_2_cleanup_categorization, CategorizationPage],
    [m2C, r.merge_2_cleanup_categorization_statistics, CategorizationStatisticsPage],
    [m2C, r.merge_2_cleanup_dashboard, Merge2Dashboard],

    [m2A, r.merge_2_analytics_supplier_segmentation, KOISupplierSegmentation],
    [m2A, r.merge_2_analytics_ppv, KOIPurchasePriceVariance],
    [m2A, r.merge_2_analytics_spend_concentration, SpendConcentration],
    [m2A, r.merge_2_analytics_explore_categories, KoiKeyDataFacts],
    [m2A, r.merge_2_analytics_dashboard, Merge2Dashboard],

    [m2M, r.merge_2_merge_common_suppliers, CommonSuppliersPage],

    [mxM1O, r.merge_2_merge_taxonomy_mapper_baseline_1, TaxonomyMapperPage],
    [mxM1N, r.merge_2_merge_taxonomy_mapper_target_1, TaxonomyMapperPage],
    [mxM2O, r.merge_2_merge_taxonomy_mapper_baseline_2, TaxonomyMapperPage],
    [mxM2N, r.merge_2_merge_taxonomy_mapper_target_2, TaxonomyMapperPage],
    [mxM3O, r.merge_2_merge_taxonomy_mapper_baseline_3, TaxonomyMapperPage],
    [mxM3N, r.merge_2_merge_taxonomy_mapper_target_3, TaxonomyMapperPage],
    [m2M, r.merge_2_merge_dashboard, Merge2Dashboard],

    [mMNO, r.merge_2_master_taxonomy_compare_baseline, TaxonomyMapperPage],
    [mM1, r.merge_2_master_taxonomy_viewer, TaxonomyEditorViewOnlyPage],
    [mM1, r.merge_2_master_taxonomy_builder, TaxonomyEditorPage],
    [mM1, r.merge_2_master_taxonomy_compare_un, TaxonomyEditorPage],
]) : []).concat(environment.package === 'merge_2' && (environment.dashboardVariant === 'simple' || environment.dashboardVariant === '?') ? [
    // Smaller version of the Master Page in the dashboard
    [m2C, r.merge_2_cleanup_taxonomy_viewer_id, TaxonomyEditorPage],
    [m2C, r.merge_2_cleanup_taxonomy_viewer, TaxonomyEditorPage],
] : []).concat(environment.package === 'merge_2' && (environment.dashboardVariant === 'expanded') ? [
    // Expanded version of the Master Page in the dashboard
    [m2C, r.merge_2_cleanup_taxonomy_viewer_id, TaxonomyEditorViewOnlyPage],
    [m2C, r.merge_2_cleanup_taxonomy_viewer, TaxonomyEditorViewOnlyPage],
    [mM1, r.merge_2_master_taxonomy_viewer_baseline, TaxonomyEditorViewOnlyPage],
    [mM1, r.merge_2_master_taxonomy_builder_target, TaxonomyEditorPage],
    [mM1, r.merge_2_master_taxonomy_compare_un, TaxonomyEditorPage],
    [mM1, r.merge_2_master_taxonomy_compare_baseline, TaxonomyEditorPage],
] : []).concat(environment.package === 'merge_x' ? [
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Merge X Dashboard
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    [mxC, r.merge_x_cleanup_taxonomy_viewer_id, TaxonomyEditorViewOnlyPage],
    [mxC, r.merge_x_cleanup_taxonomy_viewer, TaxonomyEditorViewOnlyPage],
    [mxC, r.merge_x_cleanup_supplier_normalization, SupplierNormalizationPage],
    [mxC, r.merge_x_cleanup_categorization_statistics, CategorizationStatisticsPage],
    [mxC, r.merge_x_cleanup_categorization_rev_hack, CategorizationPage], // Tested (SHV)
    [mxC, r.merge_x_cleanup_categorization, CategorizationPage], // Tested (SHV)
    [mxC, r.merge_x_cleanup_dashboard, MergeXDashboard],

    [mxA, r.merge_x_analytics_supplier_segmentation, KOISupplierSegmentation],
    [mxA, r.merge_x_analytics_ppv, KOIPurchasePriceVariance],
    [mxA, r.merge_x_analytics_spend_concentration, SpendConcentration],
    [mxA, r.merge_x_analytics_explore_categories, KoiKeyDataFacts],
    [mxAf, r.merge_x_analytics_opportunity_dashboard, AdvancedDashboard],
    [mxA, r.merge_x_analytics_dashboard, MergeXDashboard],

    [mxM, r.merge_x_merge_common_suppliers, CommonSuppliersPage],

    [mxM1O, r.merge_x_merge_taxonomy_mapper_baseline_1, TaxonomyMapperPage],
    [mxM1N, r.merge_x_merge_taxonomy_mapper_target_1, TaxonomyMapperPage],
    [mxM2O, r.merge_x_merge_taxonomy_mapper_baseline_2, TaxonomyMapperPage],
    [mxM2N, r.merge_x_merge_taxonomy_mapper_target_2, TaxonomyMapperPage],
    [mxM3O, r.merge_x_merge_taxonomy_mapper_baseline_3, TaxonomyMapperPage],
    [mxM3N, r.merge_x_merge_taxonomy_mapper_target_3, TaxonomyMapperPage],
    [mxM, r.merge_x_merge_categorization, CategorizationPage], // TODO: test
    [mxM, r.merge_x_merge_dashboard, MergeXDashboard],
    [mxMf, r.merge_x_merge_advanced_dashboard, AdvancedDashboard],

    [mMNO, r.merge_x_master_taxonomy_compare_baseline, TaxonomyMapperPage],
    [mM1, r.merge_x_master_taxonomy_viewer, TaxonomyEditorViewOnlyPage],
    [mM1, r.merge_x_master_taxonomy_builder, TaxonomyEditorPage],
    // [mM1, r.merge_x_master_taxonomy_compare_un, TaxonomyEditorPage],

    [mM1, r.merge_x_master_dashboard, MergeXDashboard],

    [mxM, r.merge_x_taxonomy_suggestor_id, TaxonomySuggestorPage],
    [mxM, r.merge_x_taxonomy_suggestor, TaxonomySuggestorPage],
    [mxM, r.merge_x_taxonomy_builder_id, TaxonomyEditorPage],
    [mxM, r.merge_x_taxonomy_builder, TaxonomyEditorPage],
    [mxM, r.merge_x_taxonomy_health_check, TaxonomyHealthCheckPage],

    // Looker Preview Page (for SHV)
    [noWrapper, r.merge_x_master_looker_opp_old, LookerPreviewPage, {
        componentProps: {
            lookerUrl: C.profile.lookerUrls?.at(5) || '',
        },
    }],
    [noWrapper, r.merge_x_master_looker_opp_new, LookerPreviewPage, {
        componentProps: {
            lookerUrl: C.profile.lookerUrls?.at(4) || ''
        },
    }],
    [noWrapper, r.merge_x_master_looker_adv_all, LookerPreviewPage, {
        componentProps: {
            lookerUrl: C.profile.lookerUrls?.at(0) || '',
        },
    }],
    [noWrapper, r.merge_x_master_looker_subsidiary_dashboard, LookerPreviewPage, {
        componentProps: {
            subsidiaryLookerUrls: C.profile.lookerUrls ? [
                C.profile.lookerUrls.at(1) || '',
                C.profile.lookerUrls.at(3) || '',
                C.profile.lookerUrls.at(2) || '',
            ] : undefined,
        },
    }],

    // By default, go to the Cleanup dashboard
    [mxC, r.merge_x_dashboard, MergeXDashboard],
] : []).concat(environment.package === 'merge_x' && (environment.dashboardVariant === 'simple' || environment.dashboardVariant === '?') ? [

    // Smaller version of the Master Page in the dashboard
    [mxC, r.merge_x_cleanup_taxonomy_viewer_id, TaxonomyEditorPage],
    [mxC, r.merge_x_cleanup_taxonomy_viewer, TaxonomyEditorPage],
    [mM1, r.merge_x_master_taxonomy_builder_target, TaxonomyEditorPage],

] : []).concat(environment.package === 'merge_x' && (environment.dashboardVariant === 'expanded') ? [

    // Expanded version of the Master Page in the dashboard
    [mxC, r.merge_x_cleanup_taxonomy_viewer_id, TaxonomyEditorViewOnlyPage],
    [mxC, r.merge_x_cleanup_taxonomy_viewer, TaxonomyEditorViewOnlyPage],
    [mM1, r.merge_x_master_taxonomy_viewer_baseline, TaxonomyEditorViewOnlyPage],
    [mM1, r.merge_x_master_taxonomy_builder_target, TaxonomyEditorPage],
    [mM1, r.merge_x_master_taxonomy_compare_un, TaxonomyEditorPage],
    [mM1, r.merge_x_master_taxonomy_compare_baseline, TaxonomyEditorPage],
    [mM1, r.merge_x_master_dashboard, MergeXDashboard],

] : []).concat(environment.package === 'merge_x_cleanup' ? [
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Merge X Cleanup Dashboard
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    [mcS, r.merge_xc_single_dashboard, MergeXCleanupDashboard],
    [mcS, r.merge_xc_single_taxonomy_builder_id, TaxonomyEditorPage],
    [mcS, r.merge_xc_single_taxonomy_builder, TaxonomyEditorPage],
    [mcS, r.merge_xc_single_categorization_statistics, CategorizationStatisticsPage],
    [mcS, r.merge_xc_single_categorization_rev_hack, CategorizationPage],
    [mcS, r.merge_xc_single_categorization, CategorizationPage],
    [mcS, r.merge_xc_single_advanced_dashboard, AdvancedDashboard],
    [mcS, r.merge_xc_single_explore_categories, KoiKeyDataFacts],
    [mcS, r.merge_xc_single_spend_concentration, SpendConcentration],
    [mcS, r.merge_xc_single_ppv, KOIPurchasePriceVariance],

    [mcC, r.merge_xc_combined_dashboard, MergeXCleanupDashboard],
    [mcC, r.merge_xc_combined_taxonomy_builder, TaxonomyEditorPage],
    [mcC, r.merge_xc_combined_supplier_normalization, SupplierNormalizationPage],
    [mcC, r.merge_xc_combined_categorization, CategorizationPage, {
        componentProps: {
            readOnly: true,
        } as CategorizationPageProps,
    }],
    [mcC, r.merge_xc_combined_categorization_statistics, CategorizationStatisticsPage],
    [mcC, r.merge_xc_combined_advanced_dashboard, AdvancedDashboard],
    [mcC, r.merge_xc_combined_explore_categories, KoiKeyDataFacts],
    [mcC, r.merge_xc_combined_spend_concentration, SpendConcentration],
    [mcC, r.merge_xc_combined_ppv, KOIPurchasePriceVariance],
] : []).concat(environment.package === 'sales_demo' ? [
    [deC, r.demo_cleanup_dashboard, SalesDemoDashboardCleanup],
    [deC, r.demo_cleanup_supplier_normalization, SupplierNormalizationPage],
    [deC, r.demo_cleanup_categorization, CategorizationPage],
    [deC, r.demo_cleanup_taxonomy_health_check, TaxonomyHealthCheckPage],
    [deC, r.demo_cleanup_taxonomy_suggestion_v1, TaxonomySuggestionDemoPage],
    [deC, r.demo_cleanup_taxonomy_suggestion_v2, TaxonomySuggesterPageV2],
    [deC, r.demo_cleanup_taxonomy_builder, TaxonomyEditorPage],
    [deC, r.demo_cleanup_taxonomy_builder_id, TaxonomyEditorPage],

    [deA, r.demo_cleanup_analytics_dashboard, SalesDemoDashboardAnalytics],
    [deA, r.demo_cleanup_analytics_koi_ss, KOISupplierSegmentation],
    [deA, r.demo_cleanup_analytics_koi_kdf, KoiKeyDataFacts],
    [deA, r.demo_cleanup_analytics_koi_ppv, KOIPurchasePriceVariance],
    [deA, r.demo_cleanup_analytics_koi_sc, SpendConcentration],
    [deA, r.demo_cleanup_analytics_contract_insights, ContractInsightsPage],
    [deA, r.demo_cleanup_analytics_contract_analytics, ContractAnalyticsPage],
    [deA, r.demo_cleanup_analytics_contract_opportunities, ContractOpportunities],
    [deAf, r.demo_cleanup_analytics_adv_dashboard, AdvancedDashboard],

    [deC, r.demo_cleanup_opportunities, OpportunitiesTrello],
    [deC, r.demo_cleanup_golden_record, GoldenRecordPage],

    // [deS, r.demo_synergy, ManageDataPage],
    [deS, r.demo_synergy_suppliers, SynergySuppliersPage],
    [deS, r.demo_synergy_categories, SynergyCategoriesPage],
    [deS, r.demo_synergy_spend, SynergyDashboardPage],
    // [deS, r.demo_synergy_data, ManageMasterDataPage],
    [deS, r.demo_synergy_analytics_dashboard, SalesDemoSynergyAnalyticsDashboard],
    [deS, r.demo_synergy_opportunities_id, OpportunitiesTrello],
    [deS, r.demo_synergy_opportunities, OpportunitiesTrello],
    [deS, r.demo_synergy_report, ReportingPage],

    [deM, r.demo_merge_review, CategorizationPage], // TODO: test
    [deM, r.demo_merge_review_result, CategorizationPage], // TODO: test
    [deM, r.demo_merge_taxonomy_viewer, TaxonomyEditorViewOnlyPage],
    [deM, r.demo_merge_taxonomy_viewer_id, TaxonomyEditorViewOnlyPage],
    [deM, r.demo_merge_taxonomy_builder, TaxonomyEditorPage],
    [deM, r.demo_merge_taxonomy_builder_id, TaxonomyEditorPage],
    [deM, r.demo_merge_taxonomy_suggester, TaxonomySuggestorPage],
    [deM, r.demo_merge_taxonomy_mapper_review, TaxonomyMapperReviewPage],
    [deM, r.demo_merge_taxonomy_mapper, TaxonomyMapperPage],
    [deM, r.demo_merge_cat_review, CategorizationPage],
    [deM, r.demo_merge_dashboard, SalesDemoSynergyMergeDashboard],
] : []).concat(environment.dashboardVariant === 'minimal' ? [
    [sim, r.job_dashboard, MinimalDashboard], // Takes precedence over DashBoard
] : []);
// endregion

// cast the specific type used in this file to a generic type used by the main router
export const routing: RouteSpecification[] = ([] as RouteSpecification[])
    .concat(wrappedRouting as RouteSpecification[])
// Add more routes like this: .concat([] as RouteSpecification[])
