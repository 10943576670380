import {
    Checkbox,
    Divider,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    MenuList,
    OutlinedInput,
    Typography
} from "@mui/material";
import Select from "@mui/material/Select";
import {ALL_GROUPS} from "../../../services/ApiTypes";
import React from "react";
import {useStores} from "../../../stores";
import {observer} from "mobx-react-lite";
import {getAppEnvironment} from "../../../routing/routing.utils";
import {useLocation} from "react-router";

export const MithraStaffDebugMenu = observer(() => {
    const {authStore} = useStores();
    const location = useLocation();
    const appEnv = getAppEnvironment(location);
    return <div style={{marginBottom: '.5em'}}>
        <MenuItem disabled>
            <Typography variant="inherit">
                User ID: {authStore.userId}
            </Typography>
        </MenuItem>
        <MenuItem disabled>
            <Typography variant="inherit">Environment: {'' + appEnv}</Typography>
        </MenuItem>
        <MenuList>
            <FormControl sx={{m: 1, width: 300}}>
                <InputLabel id="authorization-select-label">Authorization Groups</InputLabel>
                <Select
                    labelId="authorization-select-label"
                    id="authorization-select"
                    multiple
                    value={authStore.user?.groups || []}
                    onChange={e => authStore.devopsSetGroups(e.target.value as string[])}
                    input={<OutlinedInput label="Authorization Groups"/>}
                    renderValue={(values) => values.join(', ')}
                    // MenuProps={MenuProps}
                >
                    {ALL_GROUPS.map(g =>
                        <MenuItem key={g} value={g}>
                            <Checkbox checked={authStore.user?.groups.includes(g) || false}/>
                            <ListItemText primary={g}/>
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </MenuList>

        <Divider/>

    </div>
})