import {makeAutoObservable} from "mobx";
import {InitiativeSuccessStep} from "../../ApiTypes";
import {InitiativeInstanceStore} from "./InitiativeInstanceStore";
import dayjs, {Dayjs} from "dayjs";

export class InitiativeSuccessStepController {
    editing = false;
    w_title = '';

    constructor(public iis: InitiativeInstanceStore, public index: number) {
        makeAutoObservable(this);
    }

    get instance(): InitiativeSuccessStep {
        if (this.index > (this.iis.instance.success_step?.length ?? 0)) {
            console.log('InitiativeSuccessStepController ' + this.index);
            console.log(this.iis.instance.success_step);
        }
        return this.iis.instance.success_step![this.index];
    }

    get isNew(): boolean {
        return ((this.iis.instance.success_step?.length ?? 0) - 1) === this.index && this.instance.title === '';
    }

    set title(value: string) {
        this.w_title = '';
        this.instance.title = value;
        if (this.instance.title === '') this.w_title = 'Milestone Title is required!';
        if (this.instance.title.length > 256) this.w_title = 'Milestone Title is too long!'
    }

    get dueDate() {
        return dayjs(this.instance.due_date);
    }

    set dueDate(value: Dayjs) {
        this.instance.due_date = value.toDate();
    }

    set description(value: string) {
        this.instance.description = value;
    }

    doneEditing() {
        // Force evaluation of title warnings
        this.title = this.instance.title;
        if (this.w_title === '') this.editing = false;
    }

    startEditing() {
        if (this.iis.ss_c.filter(c => c.editing).length > 0) return;
        this.editing = true;
    }

    toggleAchieved() {
        this.instance.achieved = !this.instance.achieved;
    }
}
