/* eslint-disable @typescript-eslint/no-unused-vars */
import {AppConfiguration} from "./app-configuration-types";
import {ONLY_FOR_US} from "../build";
export const C: AppConfiguration = {
    package: 'merge_2',
    dashboardVariant: 'simple',
    menu: 'hidden',
    profile: {
        locale: 'en-gb',
        currency: 'GBP',
        p_name_col_name: 'Asset name',
        p_name_col_width: 100,
        p_description_col_name: 'Description',
        p_description_col_width: 150,
        p_context_1_col_name: 'Segment',
        p_context_pref: [1],
        disabledCards: [
            'taxonomy-builder',
            'koi-spend-segmentation',
            'koi-ppv',
            'koi-spend-concentration',
        ],
        additionalSearchKeys: [
            'part__p_mat_name',
            'part__p_txt_1',
        ],
        categorizationReviewSubRowRelationData: [{
            relation: "part",
            columns: [
                {
                    db_column: "p_mat_name",
                    colSpec: {cls: '', txt: 'P&L Category', width: '5em'}
                },
                {
                    db_column: "p_txt_1",
                    colSpec: {cls: '', txt: 'Purchase request comment', width: '5em'}
                },
            ]
        }],
        allowIncompleteTaxonomy: true,
        showReasonColumn: true,
        parentSupplierSynergyAltName: 'Normalized name',
        enableParentSupplierAdvDashboard: true,
        defaultCommonCategoryAdvDashboard: true,
        defaultParentSupplierAdvDashboard: true,
        allowReviewDownloadCsv: true,
        autoUpdateReviewStats: true,

        enableChat: ONLY_FOR_US,
        enableDataIngestion: ONLY_FOR_US,
        enableTaxonomyIngestion: ONLY_FOR_US,

        hardcodeMasterBagId: 99114,
    },
}
