import {makeAutoObservable} from "mobx";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";

export class InsightsJobManager {
    already_running?: boolean;

    constructor(private api: MithraMaterializedApi,) {
        makeAutoObservable(this);
    }

    updateRunningCount() {
        this.api.getInsightsJobRunning().then((response) => {
            if (response.status === 200)
                this.already_running = response.data > 0;
            if (this.already_running)
                setTimeout(() => {
                    this.updateRunningCount();
                }, 30000);
        }).catch(() => {
            if (this.already_running)
                setTimeout(() => {
                    this.updateRunningCount();
                }, 30000);
        });
    }

    createRecalculation() {
        this.api.createInsightsJon().then((response) => {
            if (response.status === 201)
                this.already_running = true;
        }).catch((response) => {
            if (response.status === 400) {
                this.already_running = true;
            }
        }).finally(() => {
            if (this.already_running)
                setTimeout(() => {
                    this.updateRunningCount();
                }, 30000);
        })
    }
}
