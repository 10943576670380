import dayjs from "dayjs";
import {Range} from "../utils/ts-utils";

export type DOI = Range<1, 6>;
export const DOIs: DOI[] = [1, 2, 3, 4, 5];

export const DoiStatus = [
    'Idea',
    'Quote requested / Validated',
    'Quoted / Prototyping',
    'Contract signed / Implemented',
    'EBIT effective',
]

export const Priority = [
    'Lowest',
    'Low',
    'Medium',
    'High',
    'Highest',
]

export const KpiFrequency = [
    'Daily',
    'Weekly',
    'Monthly',
    'Quarterly',
    'Yearly',
]

export enum SitRole {
    LOCAL = 'local',
    FINANCE = 'finance',
    GLOBAL = 'global',
    ADMIN = 'admin',
}

// We should be careful to have as little as possible dependency between user model in CAT & SIT,
//  until SIT auth is designed, implemented, reviewed, ...
export type SimpleSitUserProfile = {
    id: number
    email: string
    name: string
}

export type SitUserProfile = SimpleSitUserProfile & {
    first_name: string
    last_name: string
    default_company: Company
    companies: number[]
    role: SitRole
    created: string
}

export interface Country {
    id: number;
    name: string;
    code_a3: string;
}

export interface Currency {
    id: number;
    name: string;
    code: string;
}

export interface Cluster {
    id: number;
    title: string;
}

export interface Division {
    id: number;
    title: string;
    code: string;
}

export interface ConversionRate {
    id: number;
    source: Currency;
    target: Currency;
    created: Date;
    rate: number;
}

export interface Cluster {
    id: number;
    title: string;
}

export interface Company {
    id: number;
    title: string;
    code: string;
    fiscal_year_start_month: number;
    cluster: Cluster;
    currency: Currency;
    country: Country;
}

export interface LeverGroup {
    id: number;
    title: string;
}

export interface Lever {
    id: number;
    title: string;
    description: string;
    group: LeverGroup;
}

export interface Category {
    id: number;
    level: number;
    l1: string;
    l2: string;
    l3: string;
    l4: string;
}

export type CategoryLabel = Pick<Category, 'id'> & { label: string }

export interface KPIType {
    id: number;
    title: string;
    description: string;
}

export type Target = {
    id: number;
    title: string;
    target_year: number;
    baseline_spend: number;
    saving_amount: number;
    avoidance_amount: number;
    fiscal_year_saving_amount: number;
    fiscal_year_avoidance_amount: number;
    start_date: Date;
    owner: SimpleSitUserProfile | null;
    approver: SimpleSitUserProfile | null;
    local: boolean;
    lever?: Lever;
    currency_id: number;
    companies: number[];
    categories: number[];
    divisions: number[];
}

export type TargetWriter = Omit<Target, 'owner' | 'approver'>
    & {
    owner_id: number;
    approver_id: number;
}

export type InitiativeFinancial = {
    id: number;
    active: boolean;
    affected_months: number;
    baseline_spend: number;
    investment: number;
    severance: number;
    saving_amount: number;
    fiscal_year_saving_amount: number;
    normal_year_saving_amount: number;
    fiscal_carry_over_saving_amount: number;
    normal_carry_over_saving_amount: number;
    saving_percentage: number;
    carry_over_saving_percentage: number;
    avoidance_amount: number;
    fiscal_year_avoidance_amount: number;
    normal_year_avoidance_amount: number;
    fiscal_carry_over_avoidance_amount: number;
    normal_carry_over_avoidance_amount: number;
    avoidance_percentage: number;
    carry_over_avoidance_percentage: number;
    initiative: number;
    categories: number[];
    companies: number[];
    divisions: number[];
    currency_id: number;
    saving_method: number;
    parent?: number;
}

export type InitiativeStatus = {
    id: number;
    initiative: number;
    doi_stage: number;
    due_date: Date;
    created: Date;
}

export type InitiativeSuccessStep = {
    id: number;
    initiative: number;
    title: string;
    description: string;
    due_date: Date;
    achieved: boolean;
    created?: Date;
    updated?: Date;
}

export type InitiativeKPIValue = {
    id: number;
    initiative_kpi: number;
    value: number;
    created: Date;
    creator: SitUserProfile;
}

export type InitiativeKPI = {
    id: number;
    initiative: number;
    type_id: number;
    values: InitiativeKPIValue[];
    title: string;
    description: string;
    frequency: number;
    created: Date;
}

export type InitiativeDescriptions = {
    id: number;
    description: string;
    confidential_description: string;
    initiative: number;
    approval_notes: string;
}

export type Initiative = {
    id: number;
    title: string;
    locked_by: SimpleSitUserProfile | null;
    confidential: boolean;
    nda_required: boolean;
    sustainability: boolean;
    risk_reduction: boolean;
    compliance: boolean;
    start_date: Date;
    created: Date;
    updated: Date;
    owner: SimpleSitUserProfile | null;
    responsible: SimpleSitUserProfile;
    approver: SimpleSitUserProfile | null;
    approved: boolean;
    local: boolean;
    lever_id: number;
    description?: InitiativeDescriptions;
    financial: InitiativeFinancial[];
    doi: InitiativeStatus[];
    success_step: InitiativeSuccessStep[];
    kpi: InitiativeKPI[];
    team: string[];
    skus: string[];
    doi_status: number;
    priority: number;
}

export type InitiativeWriter =
    Omit<Initiative, 'description' | 'financial' | 'doi' | 'success_step' | 'skus' | 'owner' | 'responsible' | 'approver'>
    & {
    description_w: string;
    description_w_confidential: string;
    approval_notes_w: string;
    financial_w: InitiativeFinancial[];
    doi_w: InitiativeStatus[];
    success_step_w: InitiativeSuccessStep[];
    skus_w: string[];
    owner_id: number;
    responsible_id: number;
    approver_id: number;
}

export type Attachment = {
    id: number;
    file: string;
    created: Date;
}

export type SaveErrorsResponse = {
    [key: string]: string[] | SaveErrorsResponse[];
}

// Cost Saving methods
export interface CSMStandard {
    old_price: number;
    new_price: number;
    quantity_per_month: number;
}

export interface CSMManufacturing {
    old_process_time: number;
    new_process_time: number;
    labor_rate: number;
    quantity_per_year: number;
}

export interface CSMOutsourcing {
    new_landed_price: number;
    marginal_costs: number;
    quantity_per_year: number;
}

export interface CSMDemandControl {
    spend_previous_period: number;
    spend_current_period: number;
}

export interface CSMCapex {
    first_offer_received: number;
    signed_offer: number;
}

export type FirstPageKpis = {
    count: number;
    total_savings: number;
    pending: number;
}

export function category_readable_str(category?: Category, join_str = ' -> '): string {
    if (!category) return '';
    let res = category.l1;
    if (category.l2.trim() !== '') {
        res += join_str + category.l2;
        if (category.l3.trim() !== '') {
            res += join_str + category.l3;
            if (category.l4.trim() !== '') {
                res += join_str + category.l4;
            }
        }
    }
    return res;
}

export function traffic_light(initiative: Initiative): string[] {
    if (initiative.doi_status === 5) return ["green", "Completed"]
    if (initiative.doi === undefined) return ["gray", ""];
    const next_due = initiative.doi.find(
        (doi) => doi.doi_stage === (initiative.doi_status + 1));
    if (!next_due) return ["gray", ""];
    const diff_day = dayjs(next_due.due_date).diff(dayjs(), 'days');
    if (diff_day >= 5) return ["green", "On track, " + diff_day.toString() + " days to next deadline"];
    if (diff_day < 5 && diff_day >= 0) return ["yellow", diff_day.toString() + " days to next deadline"];
    if (diff_day < 0) return ["red", (-1 * diff_day).toString() + " days passed deadline"];
    return ["gray", ""];
}

export type ValueType = 'rolling' | 'fiscal_year';
export type SavingValue =
    'rolling_saving'
    | 'fiscal_year_saving'
    | 'rolling_saving_adj'
    | 'fiscal_year_saving_adj'
export type AvoidanceValue =
    'rolling_avoidance'
    | 'fiscal_year_avoidance'
    | 'rolling_avoidance_adj'
    | 'fiscal_year_avoidance_adj'
export type DimensionValue = SavingValue | AvoidanceValue

export type TargetValues = 'saving'
    | 'avoidance'

type Values = {
    [key in DimensionValue]: number
}

type TValues = {
    [key in TargetValues]: number
}

export type ByApprovedRowResponse = {
    approved: number
} & Values
export type PerDoiRowResponse = {
    month: string
    doi: DOI
} & Values
export type PerCompanyLeverRowResponse = {
    id: number
    title: string
    is_doi5: boolean
    lever_group_title: string
} & Values
export type PerCompanyRowResponse = Pick<Company, 'id' | 'code' | 'title'> & Values
export type TValuePerCompanyRowResponse = Pick<Company, 'id' | 'code' | 'title'> & TValues
export type PerClusterRowResponse = Pick<Cluster, 'id' | 'title'> & {
    doi: DOI
    month: string
} & Values
export type PerClustercumSumRowResponse = Pick<Cluster, 'id' | 'title'> & {
    month: string
} & Values
export type TValuePerMonthRowResponse = {
    month: string
} & TValues
export type TValuePerClusterMonthRowResponse = Pick<Cluster, 'id' | 'title'> & {
    month: string
} & TValues

export function getTargetValue(activeValueType: ValueType): TargetValues {
    switch (activeValueType) {
        case 'rolling':
        case 'fiscal_year':
            return 'saving';
    }
}

export function getSavingsValue(activeValueType: ValueType, probabilityAdjusted: boolean): SavingValue {
    switch (activeValueType) {
        case 'rolling':
            return probabilityAdjusted ? 'rolling_saving_adj' : 'rolling_saving';
        case 'fiscal_year':
            return probabilityAdjusted ? 'fiscal_year_saving_adj' : 'fiscal_year_saving';
    }
}

export function getAvoidanceValue(activeValueType: ValueType, probabilityAdjusted: boolean): AvoidanceValue {
    switch (activeValueType) {
        case 'rolling':
            return probabilityAdjusted ? 'rolling_avoidance_adj' : 'rolling_avoidance';
        case 'fiscal_year':
            return probabilityAdjusted ? 'fiscal_year_avoidance_adj' : 'fiscal_year_avoidance';
    }
}
