import '../pages/sitStyling.scss';
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {HeaderWrapper} from "../../jobs/wrapper/HeaderWrapper";
import {MainComponent} from "../../components/main/MainComponent";
import {Grid, IconButton, Menu, MenuItem, Tab, Tabs, Typography} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {generatePath, useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import {sitRoutes} from "./sitRoutes";
import {useSitStores} from "../../stores";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {routes} from "../../routing/routes";
import {DEFAULT_APP_STATE} from "../../stores/RootAppStore";

export type SitRouteWrapperProps = {}
export const SitRouteWrapper: React.FC<SitRouteWrapperProps> = observer(({children}) => {
    const {ccStore, initiativeListStore, fbStore} = useSitStores();
    const navigate = useNavigate();
    const location = useLocation();
    const tabLocations = [
        sitRoutes.sit_initiatives,
        sitRoutes.sit_targets,
        sitRoutes.sit_financial,
        sitRoutes.sit_pipeline_dashboard,
        sitRoutes.sit_approval,
    ];
    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    useEffect(() => {
        ccStore.loadInitialData();
        initiativeListStore.reloadData(true);
        fbStore.reloadData(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // TODO: Experiment with the correct deduction of the Role before rending any View (on staging)

    const state = DEFAULT_APP_STATE;
    return <MainComponent state={state} header={<HeaderWrapper state={state}/>}>
        {tabLocations.indexOf(location.pathname) >= 0 &&
            <KoiFacade
                title={<Typography variant="h1" component="h1">
                    Initiative Tracker
                </Typography>}
                onBack={() => navigate(generatePath(routes.home))}
                backClass={""}
                next={{
                    type: 'component',
                    node: <IconButton
                        id="menu-button"
                        aria-controls={menuOpen ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={menuOpen ? 'true' : undefined}
                        onClick={(event) => {
                            setMenuOpen(!menuOpen);
                            setAnchorEl(event.currentTarget);
                        }}>
                        <SettingsIcon/>
                    </IconButton>
                }}
                className="sit-page">
                <Menu
                    id="settings-menu"
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={() => {
                        setMenuOpen(false);
                        setAnchorEl(null);
                    }}
                    MenuListProps={{
                        'aria-labelledby': 'menu-button',
                    }}>
                    <MenuItem onClick={() => navigate(generatePath(sitRoutes.sit_settings_conversion_rates))}>
                        Conversion rates
                    </MenuItem>
                </Menu>
                <Grid container
                      component="section" className="sit-page-section text-center">
                    <Grid item xs={4}>
                        <Typography variant="h5" component="h5" className="subtitle">Total Savings</Typography>
                        <Typography variant="h4" component="p" className="koi-value">
                            <CurrencyComponent
                                v={initiativeListStore.initiativeKpiRM.result?.total_savings ?? 0}
                                nDigits={3}/>
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h5" component="h5" className="subtitle">Number of Initiatives</Typography>
                        <Typography variant="h4" component="p" className="koi-value">
                            {(initiativeListStore.initiativeKpiRM.result?.count ?? 0)}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h5" component="h5" className="subtitle">Pending Approvals</Typography>
                        <Typography variant="h4" component="p" className="koi-value">
                            {(initiativeListStore.initiativeKpiRM.result?.pending ?? 0)}
                        </Typography>
                    </Grid>
                </Grid>
                <Tabs
                    component="section"
                    className="sit-page-section"
                    value={location.pathname}
                    aria-label="wrapped label tabs"
                    onChange={(event, newValue) => {
                        navigate(generatePath(newValue));
                    }}>
                    <Tab label="TARGETS" value={sitRoutes.sit_targets}/>
                    <Tab label="INITIATIVES" value={sitRoutes.sit_initiatives}/>
                    <Tab label="FINANCIAL VIEW" value={sitRoutes.sit_financial}/>
                    <Tab label="Pipeline view" value={sitRoutes.sit_pipeline_dashboard}/>
                    <Tab label="APPROVAL" value={sitRoutes.sit_approval}/>
                </Tabs>
                {children}
            </KoiFacade>}
        {tabLocations.indexOf(location.pathname) < 0 && children}
    </MainComponent>
});
