import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {
    Alert,
    Autocomplete,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {KoiFacade} from "../../../../components/koi-facade/KoiFacade";
import {generatePath, useLocation, useNavigate, useParams} from "react-router-dom";
import {useSitStores} from "../../../../stores";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Category, category_readable_str, Cluster, Company, Division} from "../../../ApiTypes";
import {sitRoutes} from "../../../routing/sitRoutes";
import {Check} from "@mui/icons-material";
import {SitUserSelect} from "../../../components/SitUserSelect";
import {SitCurrency} from "../../../components/SitCurrency";

export const TargetForm: React.FC = observer(() => {
    const {ccStore, sitApi, targetListStore, targetInstanceStore} = useSitStores();
    const navigate = useNavigate();
    const params = useParams<{ targetId: string }>();
    const location = useLocation();
    const targetId: number | 'new' = 'targetId' in params ? parseInt(params['targetId'] ?? '') || 'new' : 'new';

    const [isLoading, setIsLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    useEffect(() => {
        if (targetId === 'new')
            targetInstanceStore.setInstance({});
        else
            sitApi.getTarget(targetId).then((resp) => {
                targetInstanceStore.setInstance(resp.data);
            }).catch((_reason) => {
                navigate(generatePath(sitRoutes.sit_targets));
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetId]);

    return <KoiFacade
        title={<Typography variant="h1" component="h1">
            {targetId === 'new' ?
                'Add new Target' : 'Target details'}
        </Typography>}
        onBack={() => {
            targetInstanceStore.setInstance({});
            navigate(generatePath(sitRoutes.sit_targets));
        }}
        backClass={""}
        className="target-page">
        <form onSubmit={(event) => {
            event.preventDefault();
            setIsLoading(true);
            targetInstanceStore.createOrUpdate()
                .then(() => {
                    targetInstanceStore.setInstance({});
                    targetListStore.targetListFilter.filters = targetListStore.targetListFilter.initFilters;
                    targetListStore.targetListRM.request({filters: targetListStore.targetListFilter.filters});
                    navigate(generatePath(sitRoutes.sit_targets));
                })
                .catch(error => {
                    console.error(error)
                    setErrorMessage('Could not add target')
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }}>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3">General Information</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth margin="dense">
                                <TextField
                                    id="title"
                                    variant="outlined"
                                    label="Title"
                                    required={true}
                                    value={targetInstanceStore.instance?.title ?? ''}
                                    onChange={(event) =>
                                        targetInstanceStore.title = event.target.value
                                    }/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3">Project Planing</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <SitUserSelect
                                label="Approver"
                                placeholder="Select Approver"
                                disabled={false}
                                required={true}
                                value={targetInstanceStore.instance.approver}
                                onChange={(event, value) => {
                                    if (value !== null)
                                        targetInstanceStore.approver = value
                                }}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3">Financial</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="dense">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Target year"
                                        value={targetInstanceStore.targetYear}
                                        onChange={(value) => {
                                            if (value !== null)
                                                targetInstanceStore.targetYear = value;
                                        }}
                                        views={['year']}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl required={true} fullWidth margin="dense">
                                <InputLabel id="currency-label">Currency</InputLabel>
                                <Select
                                    labelId="currency-label"
                                    id="currency"
                                    input={<OutlinedInput label="Currency"/>}
                                    value={targetInstanceStore.instance.currency_id ?? ''}
                                    onChange={(event) => {
                                        if (typeof event.target.value === 'number')
                                            targetInstanceStore.currencyId = event.target.value;
                                    }}>
                                    {ccStore.currencyRM.result?.map((currency) => {
                                        return <MenuItem
                                            key={currency.id}
                                            value={currency.id}>{currency.code}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            {ccStore.convertToCurrency(1, targetInstanceStore.instance.currency_id ?? 0,
                                    targetInstanceStore.profileStore.currencySymbol ?? '') === 0 &&
                                <Alert severity="error" color="error">
                                    Conversion rate not found! You must add a rate for selected pair in settings.
                                </Alert>}
                        </Grid>
                        <Grid item xs={6}>
                            <SitCurrency
                                disabled={false}
                                localCurrencyId={targetInstanceStore.instance.currency_id}
                                label="Baseline spend"
                                value={targetInstanceStore.instance.baseline_spend ?? 0}
                                onChange={(event) =>
                                    targetInstanceStore.baselineSpend = parseInt(event.target.value)
                                }/>
                        </Grid>
                        <Grid item xs={12}>
                            <hr/>
                        </Grid>
                        <Grid item xs={6}>
                            <SitCurrency
                                disabled={false}
                                localCurrencyId={targetInstanceStore.instance.currency_id}
                                label="Total cost saving"
                                value={targetInstanceStore.instance.saving_amount ?? 0}
                                onChange={(event) =>
                                    targetInstanceStore.savingAmount = parseInt(event.target.value)
                                }/>
                        </Grid>
                        <Grid item xs={6}>
                            <SitCurrency
                                disabled={false}
                                localCurrencyId={targetInstanceStore.instance.currency_id}
                                label="Total cost avoidance"
                                value={targetInstanceStore.instance.avoidance_amount ?? 0}
                                onChange={(event) =>
                                    targetInstanceStore.avoidanceAmount = parseInt(event.target.value)
                                }/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3">Scope</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <SitUserSelect
                                label="Owner"
                                placeholder="Select Owner"
                                disabled={false}
                                required={true}
                                value={targetInstanceStore.instance.owner}
                                onChange={(event, value) => {
                                    if (value !== null)
                                        targetInstanceStore.owner = value
                                }}/>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="dense">
                                <InputLabel id="scope-label">Scope</InputLabel>
                                <Select
                                    labelId="scope-label"
                                    id="scope"
                                    value={targetInstanceStore.instance.local ? 1 : 2}
                                    label="Scope"
                                    onChange={(event) => {
                                        if (typeof event.target.value === 'number')
                                            targetInstanceStore.local = (event.target.value === 1);
                                    }}>
                                    <MenuItem value={1}>Local</MenuItem>
                                    <MenuItem value={2}>Global</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="dense">
                                <Autocomplete
                                    multiple selectOnFocus clearOnBlur handleHomeEndKeys
                                    id="company"
                                    limitTags={1}
                                    options={ccStore.companyRM.result ?? ([] as Company[])}
                                    getOptionLabel={(option) => option.title}
                                    value={targetInstanceStore.companyValues}
                                    disableCloseOnSelect
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Company"
                                            required={true}
                                            placeholder="Select Company"
                                            inputProps={{
                                                ...params.inputProps,
                                                required: targetInstanceStore.companyValues.length === 0
                                            }}/>}
                                    renderOption={(props, option) =>
                                        <li key={option.id} {...props}>
                                            <Checkbox
                                                checked={(targetInstanceStore.instance.companies?.indexOf(option.id) ?? -1) > -1}/>
                                            {option.title}
                                        </li>}
                                    onChange={(event, value) =>
                                        targetInstanceStore.companies = value.map((company) => company.id)
                                    }/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="dense">
                                <Autocomplete
                                    multiple selectOnFocus clearOnBlur handleHomeEndKeys
                                    id="cluster"
                                    limitTags={1}
                                    options={ccStore.clusterRM.result ?? ([] as Cluster[])}
                                    getOptionLabel={(option) => option.title}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    value={targetInstanceStore.combinedClusters}
                                    disableCloseOnSelect
                                    renderInput={(params) =>
                                        <TextField {...params} variant="outlined" label="Cluster"
                                                   placeholder="Select Cluster"/>}
                                    renderOption={(props, option) =>
                                        <li key={option.id} {...props}>
                                            <Checkbox
                                                checked={targetInstanceStore.isCheckedClusters(option.id)}
                                                indeterminate={targetInstanceStore.isIndeterminateCluster(option.id)}
                                            />
                                            {option.title}
                                        </li>}
                                    onChange={(event, value, reason) =>
                                        targetInstanceStore.setClusters(value, reason === 'removeOption')
                                    }/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="dense">
                                <Autocomplete
                                    multiple selectOnFocus clearOnBlur handleHomeEndKeys
                                    id="division"
                                    limitTags={1}
                                    options={ccStore.divisionRM.result ?? ([] as Division[])}
                                    getOptionLabel={(option) => option.title}
                                    value={targetInstanceStore.divisionValues}
                                    disableCloseOnSelect
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Division"
                                            required={true}
                                            placeholder="Select Division"
                                            inputProps={{
                                                ...params.inputProps,
                                                required: targetInstanceStore.divisionValues.length === 0
                                            }}/>}
                                    renderOption={(props, option) =>
                                        <li key={option.id} {...props}>
                                            <Checkbox
                                                checked={(targetInstanceStore.instance.divisions?.indexOf(option.id) ?? -1) > -1}/>
                                            {option.title}
                                        </li>}
                                    onChange={(event, value) =>
                                        targetInstanceStore.divisions = value.map((division) => division.id)
                                    }/>
                            </FormControl>
                            {(targetInstanceStore.divisionValues.length > 1) &&
                                <Alert severity="error" color="error">
                                    Only one division is allowed!
                                </Alert>}
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="dense">
                                <Autocomplete
                                    multiple selectOnFocus clearOnBlur handleHomeEndKeys
                                    id="category"
                                    limitTags={1}
                                    options={ccStore.categoryRM.result ?? ([] as Category[])}
                                    getOptionLabel={(option) => category_readable_str(option)}
                                    value={targetInstanceStore.categoryValues}
                                    disableCloseOnSelect
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Category"
                                            required={true}
                                            placeholder="Select Category"
                                            inputProps={{
                                                ...params.inputProps,
                                                required: targetInstanceStore.categoryValues.length === 0
                                            }}/>}
                                    renderOption={(props, option) =>
                                        <li key={option.id} {...props}>
                                            <Checkbox
                                                checked={(targetInstanceStore.instance.categories?.indexOf(option.id) ?? -1) > -1}/>
                                            {category_readable_str(option)}
                                        </li>}
                                    onChange={(event, value) =>
                                        targetInstanceStore.categories = value.map((category) => category.id)
                                    }/>
                            </FormControl>
                            {(targetInstanceStore.divisionValues.length > 1) &&
                                <Alert severity="error" color="error">
                                    Only one category is allowed!
                                </Alert>}
                        </Grid>

                        <Grid container spacing={3} className="mt-2" justifyContent="end">
                            <Grid item>
                                <Button
                                    className="mr-4"
                                    onClick={() => {
                                        // Clear form data
                                        targetInstanceStore.setInstance({});
                                        navigate(generatePath(sitRoutes.sit_targets));
                                    }}>
                                    Cancel
                                </Button>

                                <Button
                                    className="loading-button"
                                    type="submit"
                                    disabled={isLoading ||
                                        (targetInstanceStore.divisionValues.length > 1) ||
                                        (targetInstanceStore.categoryValues.length > 1)}
                                    startIcon={isLoading ? <CircularProgress size={14}/> : <Check/>}
                                    variant="contained">
                                    {targetId === 'new' ? 'Add target' : 'Update target'}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} className="mt-2" justifyContent="end">
                            <Grid item>
                                {errorMessage &&
                                    <Alert severity="error">
                                        {errorMessage}
                                    </Alert>
                                }
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </form>
    </KoiFacade>
});