import './SingleDropdownMenu.scss'
import React, {useEffect} from "react";
import {Badge, Chip, Divider, Grow, IconButton, ListItem, Menu, MenuItem, Typography} from "@mui/material";
import {AccountCircleOutlined} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {routes} from "../../routing/routes";
import {useStores} from "../../stores";
import {observer} from "mobx-react-lite";
import {useAuth0} from '@auth0/auth0-react';
import {environment} from "../../env";
import {ApprovalStatusEnum} from "../../services/classes/AiClasses";
import {MithraStaffDebugMenu} from "./debug-menu/MithraStaffDebugMenu";

const menuId = 'single-dropdown-menu';

export const SingleDropdownMenu = observer(() => {
    const {authStore, approvalStore} = useStores();
    const {logout} = useAuth0();
    const navigate = useNavigate();

    const [menuElement, setMenuElement] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(menuElement);

    useEffect(() => {
        if (approvalStore.statusCount.result === undefined)
            approvalStore.statusCount.request();
    }, [approvalStore.statusCount]);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuElement(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuElement(null);
    };

    const handleLogout = () => {
        authStore.auth0Logout(logout);
        navigate(routes.login);
    };

    const renderMenu = (
        <Menu
            anchorEl={menuElement}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}
            TransitionComponent={Grow}
        >
            <ListItem title={(authStore.userId ? `[${authStore.userId}] ` : '') + authStore.email}>
                {authStore.displayUser}
            </ListItem>

            <MenuItem disabled>
                <Typography variant="inherit">
                    Account type: {authStore.customerRole}{authStore.isMithraStaff ? ' (Staff)' : ''}
                </Typography>
            </MenuItem>

            {(environment.isTest || authStore.isMithraStaff) && <MithraStaffDebugMenu/>}

            {authStore.isCustomerSuper &&
                <MenuItem onClick={() => {
                    handleMenuClose()
                    navigate(routes.approval);
                }}>
                    Awaiting approvals
                    <span className="flex-grow-1"/>
                    {(approvalStore.getCountForStatus(ApprovalStatusEnum.PENDING) ?? 0) > 0 &&
                        <Chip size="small" label={approvalStore.getCountForStatus(ApprovalStatusEnum.PENDING)}
                              className="color-error"/>
                    }
                </MenuItem>
            }
            <Divider/>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
    );

    return <>
        <IconButton
            edge="end"
            size="medium"
            aria-label="account"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit">
            {
                (authStore.isCustomerSuper || !environment.production) &&
                (approvalStore.getCountForStatus(ApprovalStatusEnum.PENDING) ?? 0) > 0
                    ? <Badge
                        badgeContent={approvalStore.getCountForStatus(ApprovalStatusEnum.PENDING)}
                        color="error">
                        <AccountCircleOutlined/>
                    </Badge>
                    : <AccountCircleOutlined/>
            }
        </IconButton>
        {renderMenu}
    </>
})
